import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, IncidentCategoryType, VenueLocation } from "../../../common/dto/AusComply.dtos";
import { incidentLogic } from '../../../common/logic/incidentLogic';
import ButtonLink from '../../controls/ButtonLink';
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../common/PageTitle';
import SubmitIcon from '../../../resources/SubmitIcon';
import IncidentWizardChecklists from '../wizard/IncidentWizardChecklists';
import ErrorBoundary from '../../common/ErrorBoundary';
import Loading from '../../common/Loading';
import NavigationButton from '../../controls/NavigationButton';
import PageLayout from '../../layout/PageLayout';
import PlayTraxIncidentCategory from './PlayTraxIncidentCategory';
import PlayTraxNarrative from './PlayTraxNarrative';
import PlayTraxLocation from './PlayTraxLocation';
import PlayTraxDocuments from './PlayTraxDocuments';
import CrossIcon from '../../../resources/CrossIcon';
import PlayTraxPatron from './PlayTraxPatron';
import PrimaryButton from '../../controls/PrimaryButton';
import PrintNavigationButtonContainer from '../../../containers/PrintNavigationButtonContainer';
import IncidentViewDates from '../view/IncidentViewDates';
import IncidentViewSnapshot from '../view/IncidentViewSnapshot';
import IncidentViewType from '../view/IncidentViewType';
import IncidentViewCategories from '../view/IncidentViewCategories';
import IncidentViewLocations from '../view/IncidentViewLocations';
import IncidentViewPersonnel from '../view/IncidentViewPersonnel';
import IncidentViewNarrative from '../view/IncidentViewNarrative';
import IncidentViewNumber from '../view/IncidentViewNumber';
import IncidentViewRelated from '../view/IncidentViewRelated';
import IncidentViewFlags from '../view/IncidentViewFlags';
import IncidentViewDocuments from '../view/IncidentViewDocuments';
import IncidentViewParent from '../view/IncidentViewParent';
import IncidentViewPeopleAndGroups from '../view/IncidentViewPeopleAndGroups';
import IncidentViewFacialRecognition from '../view/IncidentViewFacialRecognition';
import IncidentViewChecklists from '../view/IncidentViewChecklists';
import IncidentViewRadioChannelFunctionalArea from '../view/IncidentViewRadioChannelFunctionalArea';


export interface IPlayTraxEditIncidentProps {
    incidentId: number;
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onLoadIncident: Function;
    onUpdateIncident: Function;
    onClearError: Function;
    onClear: Function;
    step: number;
    onSetStep: Function;
    onSaveDraft: Function;
    onSaveFinal: Function;
    onSaveFinalAndApprove: Function;
    onCheckIncidentDateReportingPeriod: Function;
    onErrorNotification: Function;
    history: any;
    location: any;
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    onRefreshLocation: Function;
    isUploading: boolean;
    onUploadFiles: Function;
    onUploadPatronFiles: Function;
    hasChecklists: boolean;
    onUploadAttachment: Function;
    onLoadChecklists: Function;
    isLoadingChecklists: boolean;
    isPlayTrax: boolean;
    canView: boolean;
    canEdit: boolean;
    onPrint: Function;
}

interface IPlayTraxEditIncidentState {
    errors: any[];
    canSubmit: boolean;
    validDraft: boolean;
    validSubmit: boolean;
}

class PlayTraxEditIncident extends Component<IPlayTraxEditIncidentProps, IPlayTraxEditIncidentState> {

    constructor(props: IPlayTraxEditIncidentProps) {
        super(props)

        this.state = {
            errors: [],
            canSubmit: true,
            validDraft: true,
            validSubmit: true
        }

        this.onCancel = this.onCancel.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDraft = this.onDraft.bind(this);
        this.onClearError = this.onClearError.bind(this);
        this.clearAndClose = this.clearAndClose.bind(this);
        this.onUpdateLocation = this.onUpdateLocation.bind(this);
        this.onChangeCloseAfterSave = this.onChangeCloseAfterSave.bind(this);
        this.onOpenIncidient = this.onOpenIncidient.bind(this);
        this.onPatronUpdated = this.onPatronUpdated.bind(this);
        this.onUpdateAndRefreshChecklists = this.onUpdateAndRefreshChecklists.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.onPrint = this.onPrint.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line
        if (!this.props.isLoading && this.props.incident && this.props.incident.incidentId != this.props.incidentId && this.props.incidentId != 0) {
            this.props.onLoadIncident(this.props.incidentId);
        } else if (this.props.incident && this.props.incident.incidentId > 0) {
            var errors = incidentLogic.validateIncident2(this.props.incident, this.props.incidentOptions);
            let validDraft = true;
            let validSubmit = true;
            let page = "INCIDENT_CATEGORY";
            if (errors.length > 0) {
                validDraft = errors[0].draftOk;
                validSubmit = errors[0].submitOk;
                page = errors[0].page;
            }
            //var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
            this.setState({
                errors,
                validDraft,//: validationResult.draftOk,
                validSubmit//: validationResult.submitOk
            });
        }
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                this.clearAndClose();
            }
        }

        if (!this.props.isLoading && preProps.isLoading && this.props.error != "") {
            this.clearAndClose();
            return;
        }

        // eslint-disable-next-line
        if (!this.props.isLoading && preProps.isLoading && (!this.props.incident.incidentId || this.props.incident.incidentId == 0)) {
            this.onUpdateLocation();
            // set the default venue location to be the same as the last one
            //if (incidentLogic.setVenueLocation(this.props.incident, this.props.incidentOptions, this.props.venueLocationId)) {
            //    this.onUpdate({ ...this.props.incident });
            //}
        } else if (!this.props.isLoading && preProps.isLocationLoading && !this.props.isLocationLoading) {
            this.onUpdateLocation();
        }

        // eslint-disable-next-line
        if (this.props.incident && this.props.incident != preProps.incident) {
            var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
            this.setState({
                validDraft: validationResult.draftOk,
                validSubmit: validationResult.idCheckOk,
            });
        }
    }

    onClearError() {
        this.props.onClearError();
    }

    onCancel() {
        // cancel and navigate to home
        // if never saved - clear, otherwise delete
        if (this.props.onClear) {
            this.props.onClear();
        }
        if (this.props.canView) {
            this.props.history.push('/gamblingincidentregister');
        } else {
            this.props.history.push('/home');
        }
    }

    onClose() {
        if (this.props.onClear) {
            this.props.onClear();
        }
        if (this.props.canView) {
            this.props.history.push('/gamblingincidentregister');
        } else {
            this.props.history.push('/home');
        }
    }

    clearAndClose() {
        if (this.props.onClear) {
            this.props.onClear();
        }
        if (this.props.canView) {
            this.props.history.push('/gamblingincidentregister');
        } else {
            this.props.history.push('/home');
        }
    }

    onUpdateLocation() {
        let incident = { ...this.props.incident };
        incident.locationFound = this.props.locationFound;
        incident.latitude = this.props.latitude;
        incident.longitude = this.props.longitude;
        this.props.onUpdateIncident(incident);
    }

    onUpdateAndRefreshChecklists(incident) {
        if (incidentLogic.requiresPOI(incident, this.props.incidentOptions)) {
            incidentLogic.ensureOnePOIRecordExists(incident, this.props.incidentOptions);
        } else {
            incident.incidentPatrons = [];
        }
        incident = incidentLogic.validateFunctionalAreasMatchRadioChannels(incident, this.props.incidentOptions);
        incident = incidentLogic.validateSubLocationsMatchVenueLocations(incident, this.props.incidentOptions);
        this.props.onUpdateIncident(incident);
        this.props.onLoadChecklists();
    }

    onDraft() {
        if (this.props.isUploading || this.props.isLoadingChecklists) {
            this.props.onErrorNotification("Please wait until all files are uploaded");
            return;
        }
        // prevent save if the reporting period is not correct setting or any other validation, switch to that page
        var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
        if (!validationResult.draftOk) {
            this.props.onErrorNotification(validationResult.error);
            return;
        }
        // update the location and save
        if (!this.props.incident.incidentId || this.props.incident.incidentId == 0) {
            this.onUpdateLocation();
        }
        this.props.onSaveDraft();
    }

    onSubmit() {
        if (this.props.isUploading || this.props.isLoadingChecklists) {
            this.props.onErrorNotification("Please wait until all files are uploaded");
            return;
        }
        // prevent save if the reporting period is not correct setting or any other validation, switch to that page
        var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
        if (!validationResult.idCheckOk) {
            this.props.onErrorNotification(validationResult.error);
            return;
        }
        // update the location and save
        if (!this.props.incident.incidentId || this.props.incident.incidentId == 0) {
            this.onUpdateLocation();
        }
        // Save
        this.props.onSaveFinal();
    }

    onUpdate(incident) {
        incident = incidentLogic.validateFunctionalAreasMatchRadioChannels(incident, this.props.incidentOptions);
        incident = incidentLogic.validateSubLocationsMatchVenueLocations(incident, this.props.incidentOptions);
        this.props.onUpdateIncident(incident);
    }

    onPatronUpdated(patron) {
        let incident = { ...this.props.incident };
        patron.hasChanged = true;
        incident.incidentPatrons = [patron];
        this.onUpdate(incident);
    }

    onChangeCloseAfterSave(closeAfterSave: boolean) {
        //this.setState({ closeAfterSave });
    }

    onOpenIncidient() {
        this.props.history.push('/incident/' + this.props.incident.incidentId);
    }

    onApprove() {
        if (this.props.isUploading) {
            this.props.onErrorNotification("Please wait until all files are uploaded");
            return;
        }
        if (!this.props.incident.incidentId || this.props.incident.incidentId == 0) {
            this.onUpdateLocation();
        }
        this.props.onSaveFinalAndApprove();
    }

    onPrint() {
        this.props.onPrint(this.props.incidentId, this.props.incident.incidentCategoryTypeDocuments[0].incidentCategoryTypeDocumentId);
    }

    render() {
        let patron;
        if (this.props.incident && this.props.incident.incidentPatrons && this.props.incident.incidentPatrons.length > 0 && this.props.incident.incidentPatrons[0]) {
            patron = <PlayTraxPatron
                incident={this.props.incident}
                incidentOptions={this.props.incidentOptions}
                incidentPatron={this.props.incident.incidentPatrons[0]}
                onUpdated={updatedPatron => this.onPatronUpdated(updatedPatron)}
            />;
        }

        let checklists;
        if (this.props.hasChecklists && !this.props.isLoadingChecklists) {
            checklists = <IncidentWizardChecklists onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} onSwipedLeft={() => { }} onSwipedRight={() => { }} />;
        }

        let print;
        if (this.props.incident && this.props.incident.incidentCategoryTypeDocuments && this.props.incident.incidentCategoryTypeDocuments.length > 0) {
            print = <div style={{ flex: 0 }}>
                <PrintNavigationButtonContainer onPrint={this.onPrint} />                
            </div>
        }

        return (
            <PageLayout
                header={<PageTitle title={"PlayTrax"} headerStyle={{ textAlign: 'center' }} />}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
                notFullWidth={true}
                footerContent={<Grid container spacing={0}>
                    <Grid item xs={12}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            padding: '0 20px'
                        }}>
                            <div style={{ flex: 0 }}>
                                <NavigationButton
                                    onClick={this.onCancel}
                                    text={"Close"}
                                    isError={true}
                                    Icon={CrossIcon} />
                            </div>
                            {print}
                            <div style={{ flex: 0 }}>
                                <NavigationButton
                                    onClick={this.onSubmit}
                                    text={"Submit"}
                                    active={this.props.canEdit}
                                    isSuccess={this.state.validSubmit && !this.props.isLoadingChecklists}
                                    Icon={SubmitIcon} />
                            </div>
                        </div>
                    </Grid>
                </Grid>}
            >
                { this.props.canEdit ? <ErrorBoundary>
                    <PlayTraxNarrative incident={this.props.incident} onUpdateIncident={this.onUpdate} />
                    {this.props.isLoadingChecklists && (
                        <Loading />
                    )}
                    {checklists}
                    <PlayTraxLocation incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate}
                        isLocationLoading={this.props.isLocationLoading}
                        locationError={this.props.locationError}
                        locationFound={this.props.locationFound}
                        latitude={this.props.latitude}
                        longitude={this.props.longitude}
                        onUpdateLocation={this.props.onRefreshLocation} />
                    <PlayTraxIncidentCategory incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdateAndRefreshChecklists} />
                    <>
                        {patron}
                    </>
                    <PlayTraxDocuments incident={this.props.incident} isUploading={this.props.isUploading} onUploadFiles={this.props.onUploadFiles} onUpdateIncident={this.onUpdate} />

                    {(this.props.incident.canApprove && !this.props.incident.approved && !this.props.isLoadingChecklists) && (
                        <PrimaryButton text={"Approve"} onClick={this.onApprove} />
                    )}
                    <ButtonLink onClick={this.onOpenIncidient}>
                        <span>Open as a regular incident</span>
                    </ButtonLink>
                </ErrorBoundary> :<ErrorBoundary>
                    <IncidentViewNarrative incident={this.props.incident} />
                    <IncidentViewChecklists incident={this.props.incident} />
                    <IncidentViewLocations incident={this.props.incident} />
                    <IncidentViewCategories incident={this.props.incident} />
                    <IncidentViewPeopleAndGroups incident={this.props.incident} />
                    <IncidentViewDocuments incident={this.props.incident} />
               </ErrorBoundary>}
            </PageLayout>
        );
    }

    
}

export default withStyles(styles, { withTheme: true })(withRouter(PlayTraxEditIncident));