import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import View from '../../common/View';
import TransparentButton from '../../controls/TransparentButton';
import DefaultButton from '../../controls/DefaultButton';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import ListIcon from '../../../resources/ListIcon';
import { useDrag, DragSourceMonitor, DragSourceConnector, ConnectDragSource, DragSource } from 'react-dnd'
import { ItemTypes } from './ItemTypes';
import { IChecklistTemplateItemDropProps } from './ChecklistTemplateItemDrop'


export interface IChecklistTemplateItemCardDragProps {
    checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem;
    index: number;
    onMove: Function;
    // Collected Props
    connectDragSource: ConnectDragSource;
    isDragging?: boolean;
    isDropped?: boolean;
    style?: any;
}

class ChecklistTemplateItemCardDrag extends Component<IChecklistTemplateItemCardDragProps, any> {
    constructor(props: IChecklistTemplateItemCardDragProps) {
        super(props)
        //console.log('ChecklistTemplateItemCardDrag:constructor')
    }

    render() {
        const item = this.props.checklistTemplateItem;
        const index = this.props.index;

        return this.props.connectDragSource(
            <div key={"ChecklistTemplateItemCardDrag-" + item.temporaryId + "-" + index} style={{ display: 'block', margin: '5px', cursor: 'move' }}>
                <CardTitle>
                    <ListIcon fill={"#999"} style={{ width: '13px', height: '10px', marginBottom: '1px', marginRight: '5px' }} />
                    {item.checklistItemType}
                </CardTitle>
                {item.name && (
                    <CardTypography style={{ whiteSpace: 'none' }}>{item.name}</CardTypography>
                )}
                {
                    item.description && (
                        <CardTypography style={{ whiteSpace: 'none' }}>{item.description}</CardTypography>
                    )
                }
            </div>
        );
    }
}

export default DragSource((props: IChecklistTemplateItemCardDragProps) =>
    ItemTypes.ITEM,
    {
        beginDrag: (props: IChecklistTemplateItemCardDragProps) => { 
            //console.log('begin drag', props.checklistTemplateItem);
            return { name: props.checklistTemplateItem.checklistItemType };
        },
        endDrag(props: IChecklistTemplateItemCardDragProps, monitor: DragSourceMonitor) {
            //console.log('end drag', props.checklistTemplateItem);
            const item = monitor.getItem()
            const dropResult = monitor.getDropResult()

            if (dropResult) {
                let alertMessage = '';
                const isDropAllowed = dropResult.allowedDropEffect === 'any';

                if (isDropAllowed) {
                    //console.log('drop', dropResult, props.checklistTemplateItem.temporaryId);
                    if (props.onMove) {
                        props.onMove(props.checklistTemplateItem, dropResult.parentId, dropResult.position);
                    }
                }
            }
        },
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }),
)(ChecklistTemplateItemCardDrag)