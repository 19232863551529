import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import TransparentButton from '../../controls/TransparentButton';
import PageLayout from '../../layout/PageLayout';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import BroadcastNotificationSendMode from './BroadcastNotificationSendMode';
import BroadcastNotificationAccessLevels from './BroadcastNotificationAccessLevels';
import BroadcastNotificationUserRoles from './BroadcastNotificationUserRoles';
import Information from '../../common/Information';

export interface IBroadcastNotificationProps {
    broadcastNotificationId: number;
    venueId: number;
    securityFirmId: number;
    broadcastNotification: AusComplyDtos.BroadcastNotification;
    error?: string;
    isLoading: boolean;
    isSaving: boolean;
    onLoad: Function;
    onCreate: Function;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
    history: any;
    location: any;
}

export default class BroadcastNotification extends Component<IBroadcastNotificationProps, any> {
    constructor(props: IBroadcastNotificationProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.broadcastNotificationId != undefined && this.props.broadcastNotificationId > 0) {
            this.props.onLoad(this.props.broadcastNotificationId);
        } else if (this.props.broadcastNotificationId != undefined && this.props.broadcastNotificationId < 0) {
            this.props.onCreate(this.props.venueId, this.props.securityFirmId, this.props.broadcastNotificationId * -1);
        } else {
            this.props.onCreate(this.props.venueId, this.props.securityFirmId, 0);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSaving && !this.props.isSaving && this.props.error == "") {
            this.onCancel();
        }
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        var path = this.props.location.pathname.split('/');
        var updatedPath = path.pop();
        var url = path.join('/');
        this.props.history.push(url);
    }

    onValueChanged(fieldName: string, value: any) {
        let broadcastNotification = { ...this.props.broadcastNotification };
        broadcastNotification[fieldName] = value;
        if (fieldName == "includeAssociatedUsers" && value == true) {
            broadcastNotification.onlySignedOn = true;
        }
        this.props.onUpdate(broadcastNotification);
    }

    render() {

        if (!this.props.broadcastNotification) {
            return (<></>);
        }

        let header = "Broadcast Notification";
        if(this.props.broadcastNotificationId != undefined && this.props.broadcastNotificationId < 0){
            header = "Broadcast Notification Clone";
        }

        return (
            <PageLayout
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                headerText={header}
                error={this.props.error}
                footerLeftContent={<TransparentButton onClick={() => this.onCancel()} text={"Cancel"} />}
                footerRightContent={<PrimaryButton onClick={() => this.onSave()} text={"Save"} disabled={this.props.broadcastNotification.isSent} />}
            >
                <form>
                    { this.props.broadcastNotification.isSent && (<>
                        <Information isSuccess={true} message={"This message has been sent"} />
                    </>)}
                    <BroadcastNotificationSendMode broadcastNotification={this.props.broadcastNotification} onUpdate={value => this.props.onUpdate(value)} />
                    {(this.props.broadcastNotification.sendMode == AusComplyDtos.ngtBroadcastOption.Email || this.props.broadcastNotification.sendMode == AusComplyDtos.ngtBroadcastOption.EmailAndSms) && (
                        <>
                            <TextControlGroup
                                text="Email Subject"
                                defaultValue={this.props.broadcastNotification.subject}
                                maxlength={500}
                                readonly={this.props.broadcastNotification.isSent}
                                onBlur={(value) => this.onValueChanged("subject", value)}
                            />
                            <TextAreaControlGroup
                                text={"Email Body"}
                                defaultValue={this.props.broadcastNotification.message}
                                maxLength={2000}
                                readonly={this.props.broadcastNotification.isSent}
                                onBlur={(value) => this.onValueChanged("message", value)} />
                        </>
                    )}
                    {(this.props.broadcastNotification.sendMode == AusComplyDtos.ngtBroadcastOption.Sms || this.props.broadcastNotification.sendMode == AusComplyDtos.ngtBroadcastOption.EmailAndSms) && (
                        <TextAreaControlGroup
                            text={"SMS Message"}
                            defaultValue={this.props.broadcastNotification.shortMessage}
                            readonly={this.props.broadcastNotification.isSent}
                            maxLength={130}
                            onBlur={(value) => this.onValueChanged("shortMessage", value)} />
                    )}
                    <BroadcastNotificationAccessLevels broadcastNotification={this.props.broadcastNotification} onUpdate={value => this.props.onUpdate(value)} />
                    <CheckboxControlGroup
                        label={" "}
                        text={"Include associated security firm users"}
                        defaultValue={this.props.broadcastNotification.includeAssociatedUsers}
                        readonly={this.props.broadcastNotification.isSent}
                        onChanged={(value) => this.onValueChanged("includeAssociatedUsers", value)} />
                    <CheckboxControlGroup
                        label={" "}
                        text={"Only users currently logged in"}
                        readonly={this.props.broadcastNotification.includeAssociatedUsers || this.props.broadcastNotification.isSent}
                        defaultValue={this.props.broadcastNotification.onlySignedOn}
                        onChanged={(value) => this.onValueChanged("onlySignedOn", value)} />
                    <BroadcastNotificationUserRoles broadcastNotification={this.props.broadcastNotification} onUpdate={value => this.props.onUpdate(value)} />
                </form>
            </PageLayout>
        );
    }
}