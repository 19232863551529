import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import PrimaryButton from '../../controls/PrimaryButton';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TransparentButton from '../../controls/TransparentButton';
import { incidentLogic } from '../../../common/logic/incidentLogic';
import SwipeContainer from '../../layout/SwipeContainer';
import RadioButtonControl from '../../controls/RadioButtonControl';
import { RadioOption } from '../../../common/dto/common';

export interface IIncidentWizardTypeProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class IncidentWizardType extends Component<IIncidentWizardTypeProps, any> {
    constructor(props: IIncidentWizardTypeProps) {
        super(props)
        this.state = {
            incidentTypeId: 1,
            incidentTypes: [
                { incidentTypeId: 2, name: "Refusal" },
                { incidentTypeId: 1, name: "Removal" },
                { incidentTypeId: 3, name: "Other/Log" }
            ]
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (this.props.incident && this.props.incident.incidentTypeId !== this.state.incidentTypeId) {
            this.setState({
                incidentTypeId: this.props.incident.incidentTypeId
            });
        }
    }

    componentDidUpdate(prevProps, preState, snapshot) {
        if (this.props.incident && this.props.incident.incidentTypeId !== this.state.incidentTypeId) {
            this.setState({
                incidentTypeId: this.props.incident.incidentTypeId
            });
        }
    }

    onChange(incidentTypeId) {
        this.setState({ incidentTypeId: incidentTypeId }, () => {
            let newIncident = { ...this.props.incident };
            newIncident.incidentTypeId = incidentTypeId;
            // remove all categories that are not part of this new incident
            newIncident = incidentLogic.validateIncidentCategoriesMatchIncidentType(newIncident, this.props.incidentOptions);
            this.props.onUpdateIncident(newIncident);
        });
    }

    render() {
        let options: RadioOption[] = [];
        if (this.props.incidentOptions.incidentTypes) {
            this.props.incidentOptions.incidentTypes.forEach(item => {
                if (item.apiOnlyCreation === undefined || !item.apiOnlyCreation) {
                    options.push({ id: item.incidentTypeId.toString(), name: item.name });
                } else if (this.props.incident && this.props.incident.originalIncidentId) {
                    // show the original incident type if viewing the full incident details from a api only incident
                    if (item.apiOnlyCreation === undefined || !item.apiOnlyCreation) {
                        if (item.incidentTypeId == this.props.incident.originalIncidentId) {
                            options.push({ id: item.incidentTypeId.toString(), name: item.name });
                        }
                    }
                }
            });
        };

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Incident Type"}>
                    <CardContent>
                        <RadioButtonControl
                            selected={this.state.incidentTypeId}
                            options={options}
                            name={"incidentTypeId"}
                            onChange={(value) => this.onChange(value.value)} />
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}