import { of, timer } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter, debounce } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { checklistApi } from "../services/checklist";
import { attachmentApi } from "../services/attachment";
import { checklistTemplateApi } from "../services/checklistTemplate";
import { notificationShow, notificationSuccessShow } from "../actions/notification";
import {
  notifyError, notifyErrorMessage, notifyErrorResponseStatus
} from './common';
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as apiRequest from "../services/common";

import {
  AUTO_SAVE_CURRENT_CHECKLIST_REQUEST,
  UPDATE_CHECKLIST,
  updateChecklist,
  SUBMIT_CHECKLIST,
  SET_NEW_CHECKLIST,
  setNewChecklist,
  SAVE_CURRENT_CHECKLIST_REQUEST,
  saveCurrentChecklistRequest,
  saveChecklistRequest,
  SAVE_CHECKLIST_REQUEST,
  saveChecklistSuccess,
  SAVE_CHECKLIST_SUCCESS,
  saveChecklistFailure,
  SAVE_CHECKLIST_FAILURE,
  loadChecklistRequest,
  loadChecklistSuccess,
  loadChecklistFailure,
  CREATE_CHECKLIST_REQUEST,
  CREATE_CHECKLIST_SUCCESS,
  createChecklistSuccess,
  createChecklistFailure,
  LOAD_CHECKLIST_REQUEST,
  LOAD_CHECKLIST_SUCCESS,
  LOAD_CHECKLIST_FAILURE,
  loadNewChecklistRequest,
  LOAD_NEW_CHECKLIST_REQUEST,
  LOAD_NEW_CHECKLIST_REQUEST_SUCCESS,
  loadNewChecklistRequestSuccess,
  loadChecklistsRequest,
  loadChecklistsSuccess,
  loadChecklistsFailure,
  LOAD_CHECKLISTS_REQUEST,
  archiveChecklistsRequest,
  archiveChecklistsSuccess,
  archiveChecklistsFailure,
  ARCHIVE_CHECKLISTS_REQUEST,
  ARCHIVE_CHECKLISTS_SUCCESS,
  ARCHIVE_CHECKLISTS_FAILURE,
  restoreChecklistsSuccess,
  restoreChecklistsFailure,
  RESTORE_CHECKLISTS_REQUEST,
  RESTORE_CHECKLISTS_SUCCESS,
  approveChecklistsRequest,
  approveChecklistsSuccess,
  approveChecklistsFailure,
  APPROVE_CHECKLISTS_REQUEST,
  APPROVE_CHECKLISTS_SUCCESS,
  APPROVE_CHECKLISTS_FAILURE,
  closeChecklistsRequest,
  closeChecklistsSuccess,
  closeChecklistsFailure,
  CLOSE_CHECKLISTS_REQUEST,
  CLOSE_CHECKLISTS_SUCCESS,
  CLOSE_CHECKLISTS_FAILURE,
  updateChecklistStatusRequest,
  updateChecklistStatusSuccess,
  updateChecklistStatusFailure,
  UPDATE_CHECKLIST_STATUS_REQUEST,
  UPDATE_CHECKLIST_STATUS_SUCCESS,
  UPDATE_CHECKLIST_STATUS_FAILURE,
  clearChecklistSelection,
  saveChecklistItemAttachmentRequest,
  saveChecklistItemAttachmentSuccess,
  saveChecklistItemAttachmentFailure,
  SAVE_CHECKLIST_ITEM_ATTACHMENT_REQUEST,
  SAVE_CHECKLIST_ITEM_ATTACHMENT_SUCCESS,
  SAVE_CHECKLIST_ITEM_ATTACHMENT_FAILURE,
  DELETE_CHECKLIST_ITEM_ATTACHMENT_REQUEST,
  checklistsReset,
  CHECKLIST_ITEM_UPLOAD_REQUEST,
  CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS,
  checklistItemUploadRequestSuccess,
  checklistItemUploadRequestFailure
} from "../actions/checklist";
import {
  USER_CHANGED,
  userLogoutRequest
} from '../actions/userLogin';
import { loadChecklistTemplateSuccess } from "../actions/checklistTemplate";
import { fileServiceApi } from "../services/fileService";
//import * as NotificationsActionCreators from "../actions/notification";
//import { getChecklistTemplateEpic } from "./checklistTemplate";
//import { getUserSessionEpic } from "./userSession";
import { ChecklistStatuses } from '../dto/AusComply.dtos';
//import { addNotification as notify } from "reapop";
//import * as _ from "lodash";
import {
  VENUE_SECURITYFIRM_SELECT

} from "../actions/venueSecurityFirm";

const checklistResetOnUserChangeEpic = (action$, state$) =>
  action$.pipe(
    ofType(USER_CHANGED),
    map(action => checklistsReset())
  );

const checklistResetOnVenueChangeEpic = (action$, state$) =>
  action$.pipe(
    ofType(VENUE_SECURITYFIRM_SELECT),
    map(action => checklistsReset())
  );

const updateChecklistEpic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_CHECKLIST),
    debounce(() => timer(1000)),
    withLatestFrom(state$),
    filter(([action, state]) => !state.checklist.checklistSaving),
    mergeMap(([action, state]) => of(saveChecklistRequest(state.checklist.checklist)))
  );

const submitChecklistEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_CHECKLIST),
    withLatestFrom(state$),
    mergeMap(([action, state]) => of(saveChecklistRequest(state.checklist.checklist)))
  );

const saveCurrentChecklistEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_CURRENT_CHECKLIST_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) => of(saveChecklistRequest(state.checklist.checklist)))
  );
/*
export const saveCurrentChecklistEpic = (action$, store) =>
  action$.ofType(SAVE_CURRENT_CHECKLIST_REQUEST).mergeMap(action => {
    let checklistToSave = _.cloneDeep(store.getState().checklist.checklist);
    checklistToSave.checklistItems.forEach(checklistItem => {
      checklistItem.value = JSON.stringify(checklistItem.value);
    });
    return checklistApi
      .saveChecklist(checklistToSave)
      .mergeMap(response => {
        let checklistToLoad = response.checklist;
        checklistToLoad.checklistItems.forEach(checklistItem => {
          if (checklistItem.value) {
            checklistItem.value = JSON.parse(checklistItem.value);
          }
        });

        return concat$(
          Observable.of(loadChecklistSuccess(checklistToLoad)),
          Observable.of(
            notify(
              NotificationsActionCreators.notifySuccess(
                "Checklist Saved",
                "Checklist saved succesfully"
              )
            )
          )
        );
      })
      .catch(error => {
        return concat$(
          Observable.of(saveChecklistFailure(error.responseStatus)),
          Observable.of(
            notify(
              NotificationsActionCreators.notifyDanger(
                "Checklist Save Failed",
                "Checklist failed to save"
              )
            )
          )
        );
      });
  });

export const autoSaveCurrentChecklistEpic = (action$, store) =>
  action$
    .ofType(AUTO_SAVE_CURRENT_CHECKLIST_REQUEST)
    .debounceTime(350)
    .mergeMap(action => {
      let checklistToSave = _.cloneDeep(store.getState().checklist.checklist);
      checklistToSave.checklistItems.forEach(checklistItem => {
        checklistItem.value = JSON.stringify(checklistItem.value);
      });
      return checklistApi
        .saveChecklist(checklistToSave)
        .mergeMap(response => {
          let checklistToLoad = response.checklist;
          checklistToLoad.checklistItems.forEach(checklistItem => {
            if (checklistItem.value) {
              checklistItem.value = JSON.parse(checklistItem.value);
            }
          });

          return concat$(
            Observable.of(loadChecklistSuccess(checklistToLoad)),
            Observable.of(
              notify(
                NotificationsActionCreators.notifySuccess(
                  "Checklist Saved",
                  "Checklist saved succesfully"
                )
              )
            )
          );
        })
        .catch(error => {
          return concat$(
            Observable.of(saveChecklistFailure(error.responseStatus)),
            Observable.of(
              notify(
                NotificationsActionCreators.notifyDanger(
                  "Checklist Save Failed",
                  "Checklist failed to save"
                )
              )
            )
          );
        });
    });
*/

const prepareChecklistItemsForSave = (checklist: AusComplyDtos.Checklist) => {
  return checklist;
  /*var result = { ...checklist };
  result.checklistItems.forEach(checklistItem => {
    checklistItem.value = JSON.stringify(checklistItem.value);
  });
  return result;
  */
}

const prepareChecklistItemsAfterLoad = (checklist: AusComplyDtos.Checklist) => {
  return checklist;
  /*
  var result = { ...checklist };
  result.checklistItems.forEach(checklistItem => {
    if (checklistItem.value) {
      checklistItem.value = JSON.parse(checklistItem.value);
    }
  });
  return result;*/
}

const cloneChecklistTemplateForNew = (checklistTemplate: AusComplyDtos.ChecklistTemplate) => {
  let checklist = new AusComplyDtos.Checklist();
  checklist.name = checklistTemplate.name;
  checklist.checklistTemplateId = checklistTemplate.checklistTemplateId;
  checklist.checklistItems = [];
  checklist.checklistStatus = AusComplyDtos.ChecklistStatuses.InProgress;

  const items = checklistTemplate.checklistTemplateItems || [];

  items.forEach(item => {
    let newItem = new AusComplyDtos.ChecklistItem()
    newItem.checklistTemplateItemId = item.checklistTemplateItemId;
    checklist.checklistItems.push(newItem);
  });
  return checklist;
}

/*
const hasNoUnsavedAttachments = (checklist: AusComplyDtos.Checklist) => {
  return checklist.checklistItems.filter(item => {
    return (item as any).attachmentsToSave && (item as any).attachmentsToSave.length > 0;
  }).length > 0;
}
*/

/*
const saveChecklistEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_CHECKLIST_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistApi.saveChecklist(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        prepareChecklistItemsForSave(action['checklist']))
        .pipe(
          map(response => saveChecklistSuccess(prepareChecklistItemsAfterLoad(response.checklist)),
            catchError(error => notifyErrorResponseStatus(error, "saveChecklistEpic", saveChecklistFailure))
          )
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "saveChecklistEpic", saveChecklistFailure))
  )*/

const saveChecklistEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_CHECKLIST_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistApi.saveChecklist(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        state.user.details.userSession.user.venueEventId,
        state.checklist.checklist)
        .pipe(
          map(response => saveChecklistSuccess(prepareChecklistItemsAfterLoad(response.checklist), response.attachmentsChanged),
            catchError(error => notifyErrorResponseStatus(error, "saveChecklistEpic.saveChecklist", saveChecklistFailure))
          )
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "saveChecklistEpic", saveChecklistFailure))
  )


const saveChecklistSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_CHECKLIST_SUCCESS),
    debounce(() => timer(3000)),
    withLatestFrom(state$),
    filter(([action, state]) => !state.checklist.checklistSaving && state.checklist.checklist),
    map((action: any) => notificationSuccessShow("Checklist saved"))
  );

const checklistItemUploadEpic = (action$, state$) =>
  action$.pipe(
    ofType(CHECKLIST_ITEM_UPLOAD_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      fileServiceApi.uploadFiles(action["files"])
        .pipe(
          map(response => checklistItemUploadRequestSuccess(response.files, action['checklistTemplateItemId'])),
          catchError(error => notifyErrorMessage(error, "Failed to upload files", checklistItemUploadRequestFailure))
        )
    ),
    catchError(error => notifyErrorMessage(error, "Failed to upload files", checklistItemUploadRequestFailure))
  );

const checklistItemUploadSuccessEpic = action$ =>
  action$.pipe(
    ofType(CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS),
    debounce(() => timer(1000)),
    map((action: any) => notificationSuccessShow("Attachment Uploaded"))
  );

const autoSaveChecklistAfterUploadSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS),
    withLatestFrom(state$),
    debounce(() => timer(1000)),
    filter(([action, state]) => !state.checklist.checklistSaving),
    map((action: any) => saveCurrentChecklistRequest())
  );

/*
const saveChecklistItemAttachmentEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_CHECKLIST_ITEM_ATTACHMENT_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      attachmentApi.saveAttachment(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        action['attachment'],
        action['file'])
        .pipe(
          map(response =>
            saveChecklistItemAttachmentSuccess(action['checklistTemplateItemId'], response.attachment, action['file']),
            
            catchError(error => notifyError(error, "saveChecklistItemAttachmentEpic", saveChecklistItemAttachmentFailure))
          )
        )
    ),
            catchError(error => notifyError(error, "saveChecklistItemAttachmentEpic", saveChecklistItemAttachmentFailure))
  )

const saveChecklistItemAttachmentSuccessEpic = action$ =>
  action$.pipe(
    ofType(SAVE_CHECKLIST_ITEM_ATTACHMENT_SUCCESS),
    map((action: any) => notificationShow("Attachment Saved"))
  );

  
const autoSaveChecklistAfterItemAttachmentSaveEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_CHECKLIST_ITEM_ATTACHMENT_SUCCESS),
    withLatestFrom(state$),
    debounce(() => timer(3000)),
    filter(([action, state]) => hasNoUnsavedAttachments(state.checklist.checklist)),
    map((action: any) => saveCurrentChecklistRequest())
  );*/

const getChecklistEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_CHECKLIST_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistApi.getChecklist(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        state.user.details.userSession.user.venueEventId,
        action['checklistId'])
        .pipe(
          map(response => loadChecklistSuccess(prepareChecklistItemsAfterLoad(response.checklist), response.checklistTemplate)),
          catchError(error => notifyErrorResponseStatus(error, "getChecklistEpic.getChecklist", loadChecklistFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getChecklistEpic", loadChecklistFailure))
  )

const getChecklistSuccessEpic = action$ =>
  action$.pipe(
    ofType(LOAD_CHECKLIST_SUCCESS),
    map((action: any) => loadChecklistTemplateSuccess(action['checklistTemplate']))
  );

  const createChecklistSuccessEpic = action$ =>
    action$.pipe(
      ofType(CREATE_CHECKLIST_SUCCESS),
      map((action: any) => loadChecklistTemplateSuccess(action['checklistTemplate']))
    );


const getChecklistsEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_CHECKLISTS_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistApi.getChecklists(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        state.user.details.userSession.user.venueEventId,
        state.checklist.checklistsPage,
        state.checklist.checklistsPageSize,
        state.checklist.checklistfilter)
        .pipe(
          map(response => loadChecklistsSuccess(response.checklistSummaries, response.paging, response.filter)),
          catchError(error => notifyErrorResponseStatus(error, "getChecklistsEpic.getChecklists", loadChecklistsFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getChecklistsEpic", loadChecklistsFailure))
  );

const createChecklistEpic = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_CHECKLIST_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      //checklistTemplateApi.createChecklist(
      //  state.user.details.userSession.user.venueId,
      //  state.user.details.userSession.user.securityFirmId,
      //  action['checklistTemplateId'])
      apiRequest.post(AusComplyDtos.CreateChecklist, 'templateId')(state, {templateId: action['checklistTemplateId']})
        .pipe(
          map(response => createChecklistSuccess(response.checklist, response.checklistTemplate)),
          catchError(error => notifyErrorResponseStatus(error, "createChecklistEpic.createChecklist", createChecklistFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "createChecklistEpic", createChecklistFailure))
  )

const getNewChecklistEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_NEW_CHECKLIST_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistTemplateApi.getChecklistTemplate(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        action['checklistTemplateId'])
        .pipe(
          map(response => loadNewChecklistRequestSuccess(response.checklistTemplate)),
          catchError(error => notifyErrorResponseStatus(error, "getNewChecklistEpic.getChecklistTemplate", loadChecklistFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getNewChecklistEpic", loadChecklistFailure))
  )

const getNewChecklistSuccessEpic = action$ =>
  action$.pipe(
    ofType(LOAD_NEW_CHECKLIST_REQUEST_SUCCESS),
    map((action: any) => loadChecklistTemplateSuccess(action['checklistTemplate']))
  );

const getNewChecklistSuccessTemplateEpic = action$ =>
  action$.pipe(
    ofType(LOAD_NEW_CHECKLIST_REQUEST_SUCCESS),
    map((action: any) => updateChecklist(cloneChecklistTemplateForNew(action['checklistTemplate'])))
  );


const archiveChecklistsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ARCHIVE_CHECKLISTS_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistApi.archiveChecklists(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        state.user.details.userSession.user.venueEventId,
        action['checklistIds'])
        .pipe(
          map(response => archiveChecklistsSuccess(),
            catchError(error => notifyErrorResponseStatus(error, "archiveChecklistsEpic.archiveChecklists", archiveChecklistsFailure))
          )
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "archiveChecklistsEpic", archiveChecklistsFailure))
  )

const archiveChecklistsSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(ARCHIVE_CHECKLISTS_SUCCESS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => of(
      notificationSuccessShow("Archived succesfully"),
      loadChecklistsRequest(state.checklist.checklistSearch.searchText, state.checklist.checklistSearch.checklistStatuses, state.checklist.checklistSearch.startDate, state.checklist.checklistSearch.endDate)))
  );

const approveChecklistsEpic = (action$, state$) =>
  action$.pipe(
    ofType(APPROVE_CHECKLISTS_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistApi.approveChecklists(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        state.user.details.userSession.user.venueEventId,
        action['checklistIds'])
        .pipe(
          map(response => approveChecklistsSuccess(),
            catchError(error => notifyErrorResponseStatus(error, "approveChecklistsEpic.approveChecklists", approveChecklistsFailure))
          )
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "approveChecklistsEpic", approveChecklistsFailure))
  )

  const restoreChecklistsEpic = (action$, state$) =>
  action$.pipe(
    ofType(RESTORE_CHECKLISTS_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistApi.restoreChecklists(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        state.user.details.userSession.user.venueEventId,
        action['checklistIds'])
        .pipe(
          map(response => restoreChecklistsSuccess(),
            catchError(error => notifyErrorResponseStatus(error, "restoreChecklistsEpic.restoreChecklists", restoreChecklistsFailure))
          )
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "restoreChecklistsEpic", restoreChecklistsFailure))
  )

const restoreChecklistsSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(RESTORE_CHECKLISTS_SUCCESS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => of(
      notificationSuccessShow("Restored succesfully"),
      loadChecklistsRequest(state.checklist.checklistSearch.searchText, state.checklist.checklistSearch.checklistStatuses, state.checklist.checklistSearch.startDate, state.checklist.checklistSearch.endDate)))
  );


const approveChecklistsSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(APPROVE_CHECKLISTS_SUCCESS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => of(
      notificationSuccessShow("Approved succesfully"),
      loadChecklistsRequest(state.checklist.checklistSearch.searchText, state.checklist.checklistSearch.checklistStatuses, state.checklist.checklistSearch.startDate, state.checklist.checklistSearch.endDate)))
  );

const closeChecklistsEpic = (action$, state$) =>
  action$.pipe(
    ofType(CLOSE_CHECKLISTS_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistApi.closeChecklists(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        state.user.details.userSession.user.venueEventId,
        action['checklistIds'])
        .pipe(
          map(response => closeChecklistsSuccess(),
            catchError(error => notifyErrorResponseStatus(error, "closeChecklists.closeChecklists", closeChecklistsFailure))
          )
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "closeChecklists", closeChecklistsFailure))
  )

const closeChecklistsSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(CLOSE_CHECKLISTS_SUCCESS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => of(
      notificationSuccessShow("Closed succesfully"),
      loadChecklistsRequest(state.checklist.checklistSearch.searchText, state.checklist.checklistSearch.checklistStatuses, state.checklist.checklistSearch.startDate, state.checklist.checklistSearch.endDate)))
  );

export const checklistEpics = combineEpics(
  checklistResetOnUserChangeEpic,
  getChecklistsEpic,
  getNewChecklistEpic,
  saveChecklistEpic,
  getNewChecklistSuccessEpic,
  getNewChecklistSuccessTemplateEpic,
  getChecklistEpic,
  getChecklistSuccessEpic,
  //saveChecklistItemAttachmentEpic,
  //saveChecklistItemAttachmentSuccessEpic,
  //autoSaveChecklistAfterItemAttachmentSaveEpic,
  checklistItemUploadEpic,
  checklistItemUploadSuccessEpic,
  autoSaveChecklistAfterUploadSuccessEpic,
  saveChecklistSuccessEpic,
  saveCurrentChecklistEpic,
  updateChecklistEpic,
  submitChecklistEpic,
  archiveChecklistsEpic,
  archiveChecklistsSuccessEpic,
  approveChecklistsEpic,
  approveChecklistsSuccessEpic,
  closeChecklistsEpic,
  closeChecklistsSuccessEpic,
  checklistResetOnVenueChangeEpic,
  createChecklistEpic,
  createChecklistSuccessEpic,
  restoreChecklistsEpic,
  restoreChecklistsSuccessEpic
);
