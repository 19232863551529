import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import FullPageDialog from '../../layout/FullPageDialog';

export interface IVenueLocationProps {
    venueLocationId: number;
    venueLocation: AusComplyDtos.VenueLocation;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
    onResetItem: Function;
    canViewSubLocations: boolean;
    canEditSubLocations: boolean;
}

interface IVenueLocationState {
    industryCategories: MulitSelectOption[];
    subLocations: MulitSelectOption[];
}

export default class VenueLocation extends Component<IVenueLocationProps, IVenueLocationState> {
    constructor(props: IVenueLocationProps) {
        super(props)
        this.state = {
            industryCategories: [],
            subLocations: []
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onIndustryCategoriesChanged = this.onIndustryCategoriesChanged.bind(this);
        this.loadSelections = this.loadSelections.bind(this);
        this.onSelectSubLocationsChanged = this.onSelectSubLocationsChanged.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.loadSelections(this.props.venueLocation);
        }
    }

    loadSelections(venueLocation: AusComplyDtos.VenueLocation) {
        let subLocations: MulitSelectOption[] = [];
        let industryCategories: MulitSelectOption[] = [];

        if (venueLocation && venueLocation.subLocations) {
            venueLocation.subLocations.forEach((subLocation, index) => {
                let isSelected = false;
                let info = "";
                isSelected = venueLocation.selectedSubLocations.indexOf(subLocation.subLocationId) > -1;
                subLocations.push({
                    id: subLocation.subLocationId,
                    name: subLocation.name,
                    isCommon: false,
                    selected: isSelected,
                    info
                });
            });
        }

        if (venueLocation && venueLocation.industryCategorySelections) {
            venueLocation.industryCategorySelections.forEach((item, index) => {
                let industryCategory = new MulitSelectOption();
                industryCategory.id = item.industryCategoryId;
                industryCategory.name = item.name;
                industryCategory.selected = item.selected;
                industryCategory.isCommon = false;
                industryCategories.push(industryCategory);
            });
        }

        this.setState({
            subLocations,
            industryCategories
        }, () => { });
    }


    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onIndustryCategoriesChanged(value) {
        let industryCategories = [...this.state.industryCategories];
        industryCategories.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            industryCategories
        });

        let venueLocation = { ...this.props.venueLocation };
        if (venueLocation.industryCategorySelections) {
            venueLocation.industryCategorySelections.forEach((item, index) => {
                item.selected = this.state.industryCategories.filter(s => s.id === item.industryCategoryId && s.selected).length > 0;
            });
        }
        this.props.onUpdate(venueLocation)
    }

    onValueChanged(fieldName: string, value: any) {
        let venueLocation = { ...this.props.venueLocation };
        venueLocation[fieldName] = value;
        this.props.onUpdate(venueLocation)
    }

    onSelectSubLocationsChanged(items) {
        let venueLocation = { ...this.props.venueLocation };
        venueLocation.selectedSubLocations = items.map(item => item.id);
        this.props.onUpdate(venueLocation);
    }

    render() {

        if (!this.props.venueLocation) {
            return (<></>);
        }

        let rank = "0";
        if (this.props.venueLocation.rank) {
            rank = this.props.venueLocation.rank.toString();
        }

        let industries;
        let subLocations;
        if (this.props.venueLocation.venueId == 0) {
            industries = (
                <MultiSelectControl
                    cardTitle={"Industries"}
                    wrapControlInCard={true}
                    wrapInControlGroup={true}
                    items={this.state.industryCategories}
                    minimumCharactersRequired={3}
                    canShowAll={true}
                    searchText={"Type at least three letters..."}
                    onSelectItemsChanged={this.onIndustryCategoriesChanged} />
            );
        } else {
            if (this.props.canViewSubLocations){
                subLocations = (
                    <MultiSelectControl
                        cardTitle={"Sub Locations"}
                        wrapControlInCard={true}
                        wrapInControlGroup={true}
                        items={this.state.subLocations}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        isReadonly={!this.props.canEditSubLocations}
                        onSelectItemsChanged={this.onSelectSubLocationsChanged}
                        canShowAll={true} />
                );
            }
        }

        return <FullPageDialog
            open={this.props.venueLocationId != -1}
            loading={this.props.isLoading}
            footerRightContent={<PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>}
            footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>}
            notFullWidth={true}
            saving={this.props.isSaving}
            title="Location"
            onDismissed={() => this.onCancel()}>
            <TextControlGroup
                text={"Name"}
                defaultValue={this.props.venueLocation.name}
                onBlur={(value) => this.onValueChanged("name", value)} />

            <TextControlGroup
                text={"Code"}
                defaultValue={this.props.venueLocation.code}
                onBlur={(value) => this.onValueChanged("code", value)} />

            <TextControlGroup
                text={"Rank"}
                numericOnly={true}
                defaultValue={rank}
                onChange={value => this.onValueChanged("rank", value)} />

            {industries}
            {subLocations}
        </FullPageDialog>

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    <TextControlGroup
                        text={"Name"}
                        defaultValue={this.props.venueLocation.name}
                        onBlur={(value) => this.onValueChanged("name", value)} />

                    <TextControlGroup
                        text={"Code"}
                        defaultValue={this.props.venueLocation.code}
                        onBlur={(value) => this.onValueChanged("code", value)} />

                    <TextControlGroup
                        text={"Rank"}
                        numericOnly={true}
                        defaultValue={rank}
                        onChange={value => this.onValueChanged("rank", value)} />

                    {industries}

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}