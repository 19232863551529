import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName, PrintSend } from '../../../common/dto/common';
import FullPageDialog from '../../layout/FullPageDialog';
import PrintAndSend from '../../print/PrintAndSend';
import LayoutForm from '../../layout/LayoutForm';

export interface ICheckListPrintProps {
    theme: any;
    classes: any;
    checklistId: number;
    printOptions?: AusComplyDtos.PrintOptions,
    show: boolean;
    isLoading: boolean;
    onHide: Function;
    onLoadPrintOptions: Function;
    onPrint: Function;
    onErrorNotification: Function;
}

export interface ICheckListPrintState {
}

class CheckListPrint extends React.PureComponent<ICheckListPrintProps, ICheckListPrintState> {

    constructor(props: ICheckListPrintProps) {
        super(props);
        this.state = {
        };
        this.onDismissed = this.onDismissed.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show) {
            this.props.onLoadPrintOptions();
        }
        if (!this.props.isLoading && prevProps.isLoading && this.props.printOptions) {

        }
    }

    onDismissed() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    handleExport(option: string) {
        let filter = new AusComplyDtos.ChecklistFilter();
        filter.checklistId = this.props.checklistId;
        this.onPrint("EXPORT", filter, [], [], [], "NONE");
    }

    handlePrint(option: string, imageOption: string) {
        let filter = new AusComplyDtos.ChecklistFilter();
        filter.checklistId = this.props.checklistId;
        this.onPrint("DOWNLOAD", filter, [], [], [], imageOption);
    }

    handleEmail(option: string, send: PrintSend, imageOption: string) {
        let filter = new AusComplyDtos.ChecklistFilter();
        filter.checklistId = this.props.checklistId;
        this.onPrint("EMAIL", filter, send.recipients, send.ccRecipients, send.bccRecipients, imageOption);
    }

    onPrint(option: string, filter: AusComplyDtos.ChecklistFilter, to: string[], cc: string[], bcc: string[], imageOption: string) {
        if (this.props.onPrint) {
            this.props.onPrint(option, filter, to, cc, bcc, imageOption);
        }
    }

    render() {
        let pdfOptions: KeyName[] = [];

        let imageOptions: KeyName[] = [];
        imageOptions.push({ key: "LOW", name: "Include low-resolution" });
        imageOptions.push({ key: "MEDIUM", name: "Include medium-resolution" });
        imageOptions.push({ key: "HIGH", name: "Include high-resolution" });
        imageOptions.push({ key: "NONE", name: "Excluded" });

        return (
            <FullPageDialog
                open={this.props.show}
                notFullWidth={true}
                onDismissed={this.onDismissed}
                title={"Print"}>
                <PrintAndSend
                    isLoading={this.props.isLoading}
                    printOptions={this.props.printOptions}
                    onPrint={this.handlePrint}
                    onCancel={this.onDismissed}
                    onEmail={this.handleEmail}
                    pdfOptions={pdfOptions}
                    imageOptions={imageOptions} />
            </FullPageDialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CheckListPrint);