import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../common/PageTitle';
import SubTitle from '../../common/SubTitle';
import LayoutForm from '../../layout/LayoutForm';
import Loading from '../../common/Loading';
import PageMessage from '../../common/PageMessage';
import Saving from '../../common/Saving';
import View from '../../common/View';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';
import FullPageDialog from '../../layout/FullPageDialog';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Tabs from '../../common/Tabs';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import { CheckListDisplayItem } from '../../../common/dto/AusComply.dtos';
import ChecklistItemType from '../../checklists/checklistTemplate/ChecklistItemType';
import ChecklistTemplateItemCards from '../../checklists/checklistTemplate/ChecklistTemplateItemCards';
import CheckListItem from '../../checklists/checklist/CheckListItem';
import ChecklistTemplateItem from '../../checklists/checklistTemplate/ChecklistTemplateItem';
import ChecklistTemplateNavigation from '../../checklists/checklistTemplate/ChecklistTemplateNavigation';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import PageLayout from '../../layout/PageLayout';

export interface IIncidentActionTemplateProps {
    venueId: number;
    groupId: number;
    incidentActionTemplateId: number;
    incidentActionTemplate: AusComplyDtos.IncidentActionTemplate,
    groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[],
    checklistTemplateItemsVersion: number,
    isLoading: boolean,
    isSaving: boolean,
    onLoad: Function;
    onCreate: Function;
    onUpdate: Function;
    onUpdateItem: Function;
    onAddItem: Function;
    onMoveItem: Function;
    onRemoveItem: Function;
    onSave: Function;
    onReset: Function;
    onReloadGroup?: Function;
    incidentCategoryTypes: AusComplyDtos.IncidentCategoryType[],
    showFunctionalAreas: boolean,
    editFunctionalAreas: boolean,
    history: any;
    location: any;
}

interface IIncidentActionTemplateState {
    editItem: AusComplyDtos.ChecklistTemplateItem;
    preview: CheckListDisplayItem[];
    selectedTab: number;
    items: MulitSelectOption[];
    notificationTrigger?: AusComplyDtos.NotificationTrigger;
    functionalAreas: MulitSelectOption[];
}


class IncidentActionTemplate extends React.Component<IIncidentActionTemplateProps, IIncidentActionTemplateState> {

    constructor(props: IIncidentActionTemplateProps) {
        super(props)
        let editItem = new AusComplyDtos.ChecklistTemplateItem();
        editItem.temporaryId = 0;
        editItem.checklistItemType = AusComplyDtos.ChecklistItemType.Information;
        this.state = {
            editItem,
            preview: [],
            items: [],
            notificationTrigger: undefined,
            selectedTab: 0,
            functionalAreas: []
        }
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onPreview = this.onPreview.bind(this);
        this.onPreviewClose = this.onPreviewClose.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.onCancelEditItem = this.onCancelEditItem.bind(this);
        this.onUpdateItem = this.onUpdateItem.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.isDropped = this.isDropped.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.loadSelections = this.loadSelections.bind(this);
        this.onSelectFunctionalAreasChanged = this.onSelectFunctionalAreasChanged.bind(this);

    }

    componentDidMount() {
        this.onEnsureLoaded();
        this.loadSelections(this.props.incidentActionTemplate);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isSaving && prevProps.isSaving
            && this.props.groupId && this.props.groupId > 0
            && this.props.onReloadGroup) {
            this.props.onReloadGroup(this.props.groupId);
        }
        if (!this.props.isSaving && prevProps.isSaving &&
            this.props.incidentActionTemplateId != this.props.incidentActionTemplate.incidentActionTemplateId
            && this.props.incidentActionTemplate.incidentActionTemplateId > 0) {
            // reload correctly
            this.props.onReset();
            var path = this.props.location.pathname.split('/');
            var updatedPath = path.pop();
            path.push(this.props.incidentActionTemplate.incidentActionTemplateId);
            var url = path.join('/');
            this.props.history.push(url);
        } else {
            this.onEnsureLoaded();
            if (((!this.props.isLoading && prevProps.isLoading) || (!this.props.isSaving && prevProps.isSaving))
                && this.props.incidentCategoryTypes) {

                let items: MulitSelectOption[] = [];
                let selectedIncidentCategoryTypeIds: number[] = [];
                if (this.props.incidentActionTemplate && this.props.incidentActionTemplate.checklistTemplateIncidentCategoryTypes) {
                    selectedIncidentCategoryTypeIds = this.props.incidentActionTemplate
                        .checklistTemplateIncidentCategoryTypes
                        .filter(f => !f.obsolete)
                        .map(item => item.incidentCategoryTypeId);
                }
                if (this.props.incidentCategoryTypes) {
                    this.props.incidentCategoryTypes.forEach(item => {
                        let isSelected = selectedIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1;
                        items.push({
                            id: item.incidentCategoryTypeId,
                            name: item.display,
                            isCommon: false,
                            selected: isSelected
                        });
                    });
                }
                this.setState({
                    items
                }, () => this.loadSelections(this.props.incidentActionTemplate));
            }
        }
    }

    onSelectItemsChanged(items) {
        // get the old and new incidentCategoryIds
        let previousIncidentCategoryTypeIds = this.props.incidentActionTemplate.checklistTemplateIncidentCategoryTypes.map(item => item.incidentCategoryTypeId);
        let currentIncidentCategoryTypeIds = items.map(item => item.id);

        // remove
        let checklistTemplateIncidentCategoryTypes = this.props.incidentActionTemplate.checklistTemplateIncidentCategoryTypes.filter(item => currentIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1);

        // add
        items.filter(item => previousIncidentCategoryTypeIds.indexOf(item.id) == -1).forEach(item => {
            checklistTemplateIncidentCategoryTypes.push({
                checklistTemplateIncidentCategoryTypeId: 0,
                checklistTemplateId: 0,
                incidentCategoryTypeId: item.id,
                incidentCategoryType: new AusComplyDtos.IncidentCategoryType(),
                obsolete: false,
                dateEntered: new Date().toString(),
                dateModified: new Date().toString(),
                dateEnteredUtc: new Date().toString(),
                dateModifiedUtc: new Date().toString(),
                enteredBy: 0,
                lastModifiedBy: 0
            });
        });
        let incidentActionTemplate = { ...this.props.incidentActionTemplate };
        incidentActionTemplate.checklistTemplateIncidentCategoryTypes = checklistTemplateIncidentCategoryTypes;

        this.props.onUpdate(incidentActionTemplate);
    }

    onEnsureLoaded() {
        if (!this.props.isLoading && !this.props.isSaving) {
            let id = "-1";
            if (this.props.incidentActionTemplate && this.props.incidentActionTemplate.incidentActionTemplateId !== undefined) {
                if (this.props.incidentActionTemplate.venueId == this.props.venueId &&
                    this.props.incidentActionTemplate.groupId == this.props.groupId) {
                    // this module is shared between venues and groups
                    id = this.props.incidentActionTemplate.incidentActionTemplateId.toString();
                }
            }
            if (id.toString() !== this.props.incidentActionTemplateId.toString()) {
                if (this.props.incidentActionTemplateId.toString() === "0") {
                    this.props.onCreate(this.props.venueId, this.props.groupId);
                } else {
                    this.props.onLoad(this.props.incidentActionTemplateId);
                }
            }
        }
    }

    handleTabChange = (event, value) => {
        var self = this;
        this.setState({ selectedTab: value }, () => {
        });
    };

    onUpdate(incidentActionTemplate: AusComplyDtos.IncidentActionTemplate) {
        // this.props.onUpdate(incidentActionTemplate);
    }

    onClose() {
        this.props.onReset();
        var path = this.props.location.pathname.split('/');
        var updatedPath = path.pop();
        if (this.props.groupId && this.props.groupId > 0) {
            var updatedPath = path.pop();
        }
        var url = path.join('/');
        this.props.history.push(url);
    }

    onSave() {
        this.props.onSave();
    }

    flattenForPreview(preview: CheckListDisplayItem[], groupedItems: AusComplyDtos.ChecklistTemplateItem[], offset: number) {
        groupedItems.forEach(item => {
            var display = new CheckListDisplayItem();
            display.offset = offset;
            display.template = item;
            display.item = new AusComplyDtos.ChecklistItem();
            display.isRequired = item.mandatory;
            display.show = true;
            preview.push(display);
            this.flattenForPreview(preview, item.groupedItems, offset + 1);
        });
    }

    onPreview() {
        let preview: CheckListDisplayItem[] = [];
        let offset: number = 0;

        this.props.groupedChecklistTemplateItems.forEach(item => {
            var display = new CheckListDisplayItem();
            display.offset = offset;
            display.template = item;
            display.item = new AusComplyDtos.ChecklistItem();
            display.isRequired = item.mandatory;
            display.show = true;
            preview.push(display);
            this.flattenForPreview(preview, item.groupedItems, offset + 1);
        });

        this.setState({
            preview
        });
    }

    onPreviewClose() {
        this.setState({
            preview: []
        });
    }

    onEditItem(checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {
        // is there a trigger defined for this item
        let id = checklistTemplateItem.checklistTemplateItemId;
        // is there a default trigger?
        let notificationTrigger = this.props.incidentActionTemplate.notificationTriggers.find(f => f.parentId == id);
        if (!notificationTrigger) {
            if (this.props.incidentActionTemplate.defaultNotificationTrigger) {
                notificationTrigger = { ...this.props.incidentActionTemplate.defaultNotificationTrigger };
                notificationTrigger.parentId = id;
            }
        }

        this.setState({
            editItem: { ...checklistTemplateItem },
            notificationTrigger
        });
    }

    onCancelEditItem() {
        let updatedItem = { ...this.state.editItem };
        if (this.state.notificationTrigger) {
            const notificationTrigger = { ...this.state.notificationTrigger };
            let notificationTriggers = [...this.props.incidentActionTemplate.notificationTriggers].filter(f => f.parentId != notificationTrigger.parentId);
            notificationTriggers.push(notificationTrigger);
            let incidentActionTemplate = { ...this.props.incidentActionTemplate };
            incidentActionTemplate.notificationTriggers = notificationTriggers;
            this.props.onUpdate(incidentActionTemplate);
        }
        this.props.onUpdateItem(updatedItem);
        let editItem = new AusComplyDtos.ChecklistTemplateItem();
        editItem.temporaryId = 0;
        editItem.checklistItemType = AusComplyDtos.ChecklistItemType.Information;
        this.setState({
            editItem,
            notificationTrigger: undefined
        });
    }

    onUpdateItem(incidentActionTemplateItem: AusComplyDtos.ChecklistTemplateItem) {
        this.setState({ editItem: incidentActionTemplateItem })
        // console.log('onUpdateItem', incidentActionTemplateItem);
    }

    onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        // console.log('onDragEnd', source, destination);

    }

    renderDisplayItem(checklistDisplayItem: CheckListDisplayItem, index: number) {

        let key = "incidentActionitem-" + index.toString();

        return (
            <CheckListItem key={key}
                checkListDisplayItem={checklistDisplayItem}
                checklistUserSelections={[]}
                onUploadAttachment={() => { }}
                onUpdate={(i) => { }}
                isReadonly={false}
                isUploading={false} />
        )
    }

    isDropped(name: string) {

    }

    onValueChanged(fieldName: string, value: any) {
        let incidentActionTemplate = { ...this.props.incidentActionTemplate };
        incidentActionTemplate[fieldName] = value;
        this.props.onUpdate(incidentActionTemplate)
    }

    loadSelections(radioChannel: AusComplyDtos.IncidentActionTemplate) {
        let functionalAreas: MulitSelectOption[] = [];
        if (radioChannel && radioChannel.functionalAreas) {
            radioChannel.functionalAreas.forEach((functionalArea, index) => {
                let isSelected = false;
                let info = "";
                isSelected = radioChannel.selectedFunctionalAreas.indexOf(functionalArea.functionalAreaId) > -1;
                functionalAreas.push({
                    id: functionalArea.functionalAreaId,
                    name: functionalArea.name,
                    isCommon: false,
                    selected: isSelected,
                    info
                });
            });
        }
        this.setState({
            functionalAreas
        }, () => { });
    }

    onSelectFunctionalAreasChanged(items) {
        let incidentActionTemplate = { ...this.props.incidentActionTemplate };
        incidentActionTemplate.selectedFunctionalAreas = items.map(item => item.id);
        this.props.onUpdate(incidentActionTemplate);
    }

    render() {
        let self = this;
        if (this.props.isLoading) {
            return (
                <>
                    <PageTitle title={"Incident Action Template"} />
                    <Loading />
                </>
            );
        }

        if (this.props.isSaving) {
            return (
                <>
                    <PageTitle title={"Incident Action Template"} />
                    <Saving />
                </>
            );
        }

        let items;
        if (!this.props.isLoading && !this.props.isSaving) {
            if (this.props.incidentActionTemplateId == 0) {
                items = <PageMessage text={"Designer is available after save"} />
            } else {

                items = (
                    <Grid container style={{ marginTop: '20px' }}>
                        <Grid item xs={3} style={{ position: 'relative' }}>
                            <div className="checklist-toolbox">
                                <SubTitle text={"Toolbox"} />
                                <ChecklistItemType
                                    title="Group"
                                    description="Group a set of fields together"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Group}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Information"
                                    description="Add extra information or instructions"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Information}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Text Field"
                                    description="Input additional notes"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.TextBox}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Question"
                                    description="Yes/No or custom responses"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Question}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Selection"
                                    description="Select one option from custom responses"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Selection}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Multi-Select"
                                    description="Custom responses, select single or multiple answers"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.MultipleChoice}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Checkbox"
                                    description="A checkbox to tick"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Checkbox}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Date and Time"
                                    description="Input a date and/or time"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.DateTime}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Staff (single)"
                                    description="Select one staff member"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.StaffSingleSelection}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Staff (multiple)"
                                    description="Select multiple staff members"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.StaffMultipleSelection}
                                    onAdd={this.props.onAddItem}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={8}>
                            <SubTitle text={"Template Designer"} />
                            <ChecklistTemplateItemCards
                                key={"ChecklistTemplateItemCards--" + this.props.checklistTemplateItemsVersion}
                                checklistTemplateItems={this.props.groupedChecklistTemplateItems}
                                notificationTriggerParentIds={this.props.incidentActionTemplate.notificationTriggers ? this.props.incidentActionTemplate.notificationTriggers.filter(f => f.notify).map((value) => value.parentId) : []}
                                parentId={0}
                                onEdit={this.onEditItem}
                                onRemove={this.props.onRemoveItem}
                                onMove={this.props.onMoveItem} />
                        </Grid>
                    </Grid>
                );
            }
        }
        let editItem;
        if (this.state.editItem && this.state.editItem.temporaryId !== 0) {
            editItem = (
                <ChecklistTemplateItem
                    venueId={this.props.incidentActionTemplate.venueId}
                    checklistTemplateItem={this.state.editItem}
                    checklistTemplateItems={this.props.incidentActionTemplate.checklistTemplateItems}
                    options={this.state.editItem.checklistTemplateItemOptions}
                    onUpdate={value => this.onUpdateItem(value)}
                    isIncidentAction={true}
                    notificationTrigger={this.state.notificationTrigger}
                    onUpdateNotificationTrigger={value => self.setState({ notificationTrigger: value })}
                />
            );
        }

        let preview;
        if (this.state.preview.length > 0) {
            preview = this.state.preview.map((item, index) => {
                return this.renderDisplayItem(item, index);
            });
        }

        return (
            <DndProvider backend={HTML5Backend}>
                <PageLayout
                    headerText={"Incident Action Template"}
                    footerContent={
                        <ChecklistTemplateNavigation
                            onCancel={this.onClose}
                            onSave={this.onSave}
                            onPreview={this.onPreview}
                            isSaving={this.props.isSaving}
                        />}
                >
                    <View style={{ marginTop: '10px' }}>
                        <Tabs selected={this.state.selectedTab} labels={["Details", "Questions"]} onClick={index => this.handleTabChange(null, index)}></Tabs>
                    </View>
                    {this.state.selectedTab === 0 && (
                        <>
                            <LayoutForm
                                loading={this.props.isLoading}
                                saving={this.props.isSaving}
                            >
                                <form>
                                    <TextControlGroup
                                        text={"Name"}
                                        defaultValue={this.props.incidentActionTemplate.name}
                                        onBlur={(value) => this.onValueChanged("name", value)} />

                                    <TextAreaControlGroup
                                        text={"Description"}
                                        defaultValue={this.props.incidentActionTemplate.description}
                                        onBlur={(value) => this.onValueChanged("description", value)} />

                                    <SimpleDateControlGroup
                                        text={"Effective From Date"}
                                        date={this.props.incidentActionTemplate.startDate}
                                        onChange={(value) => this.onValueChanged("from", value)}
                                    />

                                    <CheckboxControlGroup
                                        text={"Active"}
                                        label={" "}
                                        isReversed={true}
                                        defaultValue={this.props.incidentActionTemplate.obsolete}
                                        onChanged={(value) => this.onValueChanged("obsolete", value)} />


                                    <MultiSelectControl
                                        cardTitle={"Categories"}
                                        wrapControlInCard={true}
                                        wrapInControlGroup={true}
                                        items={this.state.items}
                                        searchText="Type at least three letters..."
                                        minimumCharactersRequired={3}
                                        onSelectItemsChanged={this.onSelectItemsChanged}
                                        canShowAll={true} />

                                    {this.props.showFunctionalAreas &&
                                        <MultiSelectControl
                                            cardTitle={"Functional Areas"}
                                            wrapControlInCard={true}
                                            wrapInControlGroup={true}
                                            items={this.state.functionalAreas}
                                            searchText="Type at least three letters..."
                                            minimumCharactersRequired={3}
                                            onSelectItemsChanged={this.onSelectFunctionalAreasChanged}
                                            canShowAll={true} />
                                    }

                                </form>
                            </LayoutForm >
                        </>
                    )}
                    {this.state.selectedTab === 1 && (
                        <>
                            {items}
                        </>
                    )}
                    <FullPageDialog open={this.state.preview.length > 0} title="Preview" onDismissed={() => this.onPreviewClose()}>
                        {preview}
                    </FullPageDialog>
                    <FullPageDialog open={this.state.editItem.temporaryId !== 0} title="Edit" onDismissed={() => this.onCancelEditItem()}>
                        {editItem}
                    </FullPageDialog>
                </PageLayout>
            </DndProvider>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(IncidentActionTemplate));