import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { externalEventServiceApi } from '../services/externalEventService';

import * as externalEventActions from '../actions/externalEvent';
import {
    notifyError
} from './common';

const externalEventsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalEventServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => externalEventActions.externalEventsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "externalEventsRequestEpic.find", externalEventActions.externalEventsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "externalEventsRequestEpic", externalEventActions.externalEventsRequestFailure))
    );


const externalEventsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENTS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalEventServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.externalEvents.externalEvents.paging.page,
                state.externalEvents.externalEvents.paging.pageSize,
                state.externalEvents.externalEvents.filter)
                .pipe(
                    map(response => externalEventActions.externalEventsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "externalEventsRefreshEpic.find", externalEventActions.externalEventsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "externalEventsRefreshEpic", externalEventActions.externalEventsRequestFailure))
    );

const externalEventRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalEventServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['externalEventId'])
                .pipe(
                    map(response => externalEventActions.externalEventRequestSuccess(response.externalEvent)),
                    catchError(error => notifyError(error, "externalEventRequestEpic.get", externalEventActions.externalEventRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "externalEventRequestEpic", externalEventActions.externalEventRequestFailure))
    );

const externalEventCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENT_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalEventServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['ngtEntityType'],
                action['forGroupId'],
                action['forVenueId'],
                action['forSecurityFirmId'])
                .pipe(
                    map(response => externalEventActions.externalEventCreateRequestSuccess(response.externalEvent)),
                    catchError(error => notifyError(error, "externalEventCreateEpic.create", externalEventActions.externalEventCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "externalEventCreateEpic", externalEventActions.externalEventCreateRequestFailure))
    );

const externalEventSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENT_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalEventServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.externalEvents.externalEvent.externalEvent)
                .pipe(
                    map(response => externalEventActions.externalEventSaveRequestSuccess(response.externalEvent)),
                    catchError(error => notifyError(error, "externalEventUpsertEpic.upsert", externalEventActions.externalEventSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "externalEventUpsertEpic", externalEventActions.externalEventSaveRequestFailure))
    );

const externalEventSaveSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENT_SAVE_REQUEST_SUCCESS),
        map((action: any) => externalEventActions.externalEventReset())
    );

const externalEventSaveSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENT_SAVE_REQUEST_SUCCESS),
        map((action: any) => externalEventActions.externalEventsRefresh())
    );


const externalEventMessageAuditsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENTMESSAGEAUDITS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalEventServiceApi.audits(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => externalEventActions.externalEventMessageAuditsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "externalEventMessageAuditsRequestEpic.audits", externalEventActions.externalEventMessageAuditsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "externalEventMessageAuditsRequestEpic", externalEventActions.externalEventMessageAuditsRequestFailure))
    );


const externalEventMessageAuditsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENTMESSAGEAUDITS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalEventServiceApi.audits(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.externalEvents.externalEventMessageAudits.paging.page,
                state.externalEvents.externalEventMessageAudits.paging.pageSize,
                state.externalEvents.externalEventMessageAudits.filter)
                .pipe(
                    map(response => externalEventActions.externalEventMessageAuditsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "externalEventMessageAuditsRefreshEpic.find", externalEventActions.externalEventMessageAuditsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "externalEventMessageAuditsRefreshEpic", externalEventActions.externalEventMessageAuditsRequestFailure))
    );


const externalEventMessageAuditReProcessEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalEventServiceApi.reprocess(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['externalEventMessageAudit'])
                .pipe(
                    map(response => externalEventActions.externalEventMessageAuditReProcessRequestSuccess()),
                    catchError(error => notifyError(error, "externalEventMessageAuditReProcessEpic.reprocess", externalEventActions.externalEventMessageAuditReProcessRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "externalEventMessageAuditReProcessEpic", externalEventActions.externalEventMessageAuditReProcessRequestFailure))
    ); 

const externalEventMessageAuditReProcessSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalEventActions.EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST_SUCCESS),
        map((action: any) => externalEventActions.externalEventMessageAuditsRefresh())
    );

export const externalEventEpics = combineEpics(
    externalEventsRefreshEpic,
    externalEventsRequestEpic,
    externalEventRequestEpic,
    externalEventCreateEpic,
    externalEventSaveEpic,
    externalEventSaveSuccessEpic,
    externalEventSaveSuccessRefreshEpic,
    externalEventMessageAuditsRequestEpic,
    externalEventMessageAuditsRefreshEpic,
    externalEventMessageAuditReProcessEpic,
    externalEventMessageAuditReProcessSuccessEpic
);