import update from "immutability-helper";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import moment from "moment";
import {
  CHECKLIST_TEMPLATE_RESET,
  SAVE_CHECKLIST_TEMPLATE_REQUEST,
  SAVE_CHECKLIST_TEMPLATE_SUCCESS,
  SAVE_CHECKLIST_TEMPLATE_FAILURE,
  LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST,
  LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS,
  LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE,
  NEW_CHECKLIST_TEMPLATE_REQUEST,
  LOAD_CHECKLIST_TEMPLATE_REQUEST,
  LOAD_CHECKLIST_TEMPLATE_SUCCESS,
  LOAD_CHECKLIST_TEMPLATE_FAILURE,
  LOAD_CHECKLIST_TEMPLATES_REQUEST,
  LOAD_CHECKLIST_TEMPLATES_SUCCESS,
  LOAD_CHECKLIST_TEMPLATES_FAILURE,
  TOGGLE_CHECKLIST_TEMPLATE_SELECTED,
  CLEAR_CHECKLIST_TEMPLATE_SELECTION,
  SET_CHECKLIST_TEMPLATES_PAGE_SIZE,
  SET_CHECKLIST_TEMPLATES_PAGE,
  SET_CHECKLIST_TEMPLATES_SORT_PARAMETERS,
  UPDATE_CHECKLIST_TEMPLATE,
  UPDATE_CHECKLIST_TEMPLATE_ITEMS,
  LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_REQUEST,
  LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_SUCCESS,
  LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_FAILURE,
  SAVE_CHECKLIST_TEMPLATE_CATEGORY_REQUEST,
  SAVE_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS,
  SAVE_CHECKLIST_TEMPLATE_CATEGORY_FAILURE,
  LOAD_CHECKLIST_TEMPLATE_CATEGORY_REQUEST,
  LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS,
  LOAD_CHECKLIST_TEMPLATE_CATEGORY_FAILURE,
  SET_CHECKLIST_TEMPLATE_CATEGORIES_SORT_PARAMETERS,
  SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE_SIZE,
  SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE,
  CLEAR_CHECKLIST_TEMPLATE_CATEGORY_SELECTION,
  TOGGLE_CHECKLIST_TEMPLATE_CATEGORY_SELECTED,
  LOAD_CHECKLIST_PREVIEW_SUCCESS,
  LOAD_CHECKLIST_PREVIEW_REQUEST,
  COPY_CHECKLIST_TEMPLATES_SUCCESS,
  SET_CHECKLIST_TEMPLATE_ITEM,
  SET_CHECKLIST_TEMPLATE_GROUPED_ITEM,
  ADD_CHECKLIST_TEMPLATE_ITEM,
  MOVE_CHECKLIST_TEMPLATE_ITEM,
  REMOVE_CHECKLIST_TEMPLATE_ITEM
} from "../actions/checklistTemplate";
import {
  LOAD_CHECKLIST_REQUEST,
  LOAD_CHECKLIST_FAILURE
} from "../actions/checklist";
import { commonLogic } from '../logic/common';


const initialState = {
  checklistPreviewLoading: false,
  checklistPreview: undefined,
  checklistTemplateCategoriesLoading: false,
  checklistTemplateLoading: false,
  checklistTemplateSaving: false,
  checklistTemplateSaveSuccessful: false,
  checklistTemplateLoadSuccessful: true,
  checklistTemplateCategoryLoadSuccessful: true,
  checklistTemplateCategoryLoading: false,
  checklistTemplateCategories: [],
  selectedChecklistTemplateCategoryIds: [],
  checklistTemplateCategoriesPageSize: 10,
  checklistTemplateCategoriesPage: 0,
  checklistTemplateCategoriesSortBy: "name",
  checklistTemplateCategoriesSortDirection: "ASC",
  checklistTemplatesLoading: false,
  checklistTemplates: [],
  selectedChecklistTemplateIds: [],
  checklistTemplatesPageSize: 10,
  checklistTemplatesPage: 0,
  checklistTemplatesSortBy: "name",
  checklistTemplatesSortDirection: "ASC",
  checklistTemplateSearch: {
    searchText: "",
    venues: [],
    securityFirms: [],
    industryCategories: [],
    states: [],
    checklistTemplateCategories: []
  },
  checklistTemplateCategorySearch: {
    searchText: "",
    venues: [],
    securityFirms: [],
    industryCategories: [],
    states: []
  },
  checklistTemplate: {
    checklistTemplateItems: [],
    groupedChecklistTemplateItems: []
  },
  checklistTemplateItemsVersion: 1
};

function updateChecklistTemplateItems(state, checklistTemplateItems) {
  let newState = update(state, {
    checklistTemplate: {
      checklistTemplateItems: { $set: checklistTemplateItems }
    }
  });
  return newState;
}

function loadingChecklistTemplateCategories(state, isLoading) {
  let newState = update(state, {
    checklistTemplateCategoriesLoading: { $set: isLoading }
  });
  return newState;
}
function updateChecklistTemplateCategories(state, checklistTemplateCategories) {
  let newState = update(state, {
    checklistTemplateCategories: { $set: checklistTemplateCategories }
  });
  return loadingChecklistTemplateCategories(newState, false);
}
function updateChecklistTemplateCategory(state, checklistTemplateCategory) {
  let newState = update(state, {
    checklistTemplateCategory: { $set: checklistTemplateCategory }
  });
  return loadingChecklistTemplateCategory(newState, false);
}
function loadingChecklistTemplateCategory(state, isLoading) {
  let newState = update(state, {
    checklistTemplateCategoryLoading: { $set: isLoading }
  });

  if (isLoading) {
    newState = update(newState, {
      checklistTemplateCategoryLoadSuccessful: { $set: false }
    });
  }
  return newState;
}
function checklistTemplateCategoryLoaded(state, successful) {
  let newState = update(state, {
    checklistTemplateCategoryLoadSuccessful: { $set: successful }
  });
  return loadingChecklistTemplateCategory(newState, false);
}
function savingChecklistTemplateCategory(state, isSaving) {
  let newState = update(state, {
    checklistTemplateCategorySaving: { $set: isSaving }
  });
  return newState;
}
function checklistTemplateCategorySaved(state, successful) {
  let newState = update(state, {
    checklistTemplateCategorySaveSuccessful: { $set: successful }
  });
  return savingChecklistTemplateCategory(newState, false);
}
function updateChecklistTemplateCategoriesPageSize(state, pageSize) {
  let newState = update(state, {
    checklistTemplateCategoriesPageSize: { $set: pageSize }
  });
  return newState;
}
function updateChecklistTemplateCategoriesPage(state, page) {
  let newState = update(state, {
    checklistTemplateCategoriesPage: { $set: page }
  });
  return newState;
}

function toggleChecklistTemplateCategorySelected(
  state,
  checklistTemplateCategoryId
) {
  let idx = state.selectedChecklistTemplateCategoryIds.indexOf(
    checklistTemplateCategoryId
  );

  if (idx === -1) {
    let newState = update(state, {
      selectedChecklistTemplateCategoryIds: {
        $push: [checklistTemplateCategoryId]
      }
    });
    return newState;
  } else {
    let newState = update(state, {
      selectedChecklistTemplateCategoryIds: { $splice: [[idx, 1]] }
    });
    return newState;
  }
}

function clearChecklistTemplateCategorySelection(state) {
  let newState = update(state, {
    selectedChecklistTemplateCategoryIds: { $set: [] }
  });
  return newState;
}

function updateChecklistTemplateCategoriesSortParameters(
  state,
  sortBy,
  sortDirection
) {
  let newState = update(state, {
    checklistTemplateCategoriesSortBy: { $set: sortBy },
    checklistTemplateCategoriesSortDirection: { $set: sortDirection }
  });
  return newState;
}

function updateChecklistPreview(state, checklist) {
  let newState = update(state, {
    checklistPreview: { $set: checklist }
  });
  return loadingChecklistPreview(newState, false);
}
function loadingChecklistPreview(state, isLoading) {
  let newState = update(state, {
    checklistPreviewLoading: { $set: isLoading }
  });
  return newState;
}

function updateChecklistTemplate(state, checklistTemplate) {
  let newState = update(state, {
    checklistTemplate: { $set: checklistTemplate }
  });
  return groupChecklistItems(loadingChecklistTemplate(newState, false), checklistTemplate);
}

function updateGroupedChecklistTemplate(state, checklistTemplate) {
  // upgroup the items
  let newState = update(state, {
    checklistTemplate: { $set: checklistTemplate }
  });
  return loadingChecklistTemplate(newState, false);
}

function findGroup(findId: number, groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[]): AusComplyDtos.ChecklistTemplateItem | undefined {
  if (groupedChecklistTemplateItems.length > 0) {
    for (let index = 0; index < groupedChecklistTemplateItems.length; index++) {
      var item = groupedChecklistTemplateItems[index];
      if (item.checklistItemType === AusComplyDtos.ChecklistItemType.Group) {
        if (item.checklistTemplateItemId === findId) {
          if (item.groupedItems === undefined) {
            item.groupedItems = []
          }
          return item;
        } else {
          let subItem = findGroup(findId, item.groupedItems);
          if (subItem !== undefined) {
            if (subItem.groupedItems === undefined) {
              subItem.groupedItems = []
            }
            return subItem;
          }
        }
      }
    }
  }
  return undefined;
}

function groupChecklistItems(state, checklistTemplate: AusComplyDtos.ChecklistTemplate) {
  // group the items

  let groupedChecklistTemplateItems = groupChecklistItemList(checklistTemplate.checklistTemplateItems);

  let checklistTemplateItemsVersion = state.checklistTemplateItemsVersion + 1;

  updateGroupRanks(groupedChecklistTemplateItems, 0);

  let newState = update(state, {
    checklistTemplate: {
      groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
    },
    checklistTemplateItemsVersion: { $set: checklistTemplateItemsVersion }
  });
  return newState;
}

function groupChecklistItemList(checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[]) {
  if (!checklistTemplateItems) {
    return [];
  }
  // group the items
  let groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [];
  checklistTemplateItems.forEach(item => {
    if (item.temporaryParentId > 0) {
      // search for the parent
      let group = findGroup(item.temporaryParentId, groupedChecklistTemplateItems);
      if (group != undefined) {
        group.groupedItems.push(item);
      } else {
        groupedChecklistTemplateItems.push(item);
      }
    } else {
      groupedChecklistTemplateItems.push(item);
    }
  });
  return groupedChecklistTemplateItems;
}

function updateGroupRanks(checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[], baseIndex: number) {
  checklistTemplateItems.forEach((item, index) => {
    item.rank = baseIndex + index;
    updateGroupRanks(item.groupedItems, baseIndex + index);
  });
}

function loadingChecklistTemplate(state, isLoading) {
  let newState = update(state, {
    checklistTemplateLoading: { $set: isLoading }
  });

  if (isLoading) {
    newState = update(newState, {
      checklistTemplateLoadSuccessful: { $set: false }
    });
  }
  return newState;
}

function checklistTemplateLoaded(state, successful) {
  let newState = update(state, {
    checklistTemplateLoadSuccessful: { $set: successful }
  });

  return loadingChecklistTemplate(newState, false);
}

function findAndUpdateGroupItem(items: AusComplyDtos.ChecklistTemplateItem[], replaceWith: AusComplyDtos.ChecklistTemplateItem): boolean {
  if (items.length > 0) {
    for (let index = 0; index < items.length; index++) {
      var item = items[index];
      if (items[index].temporaryId === replaceWith.temporaryId) {
        items[index] = { ...replaceWith };
        return true;
      } else {
        if (findAndUpdateGroupItem(items[index].groupedItems, replaceWith)) {
          return true;
        }
      }
    }
  }
  return false;
}

function setGroupedChecklistTemplateItem(state, checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {
  let groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [...state.checklistTemplate.groupedChecklistTemplateItems];

  // update the item
  findAndUpdateGroupItem(groupedChecklistTemplateItems, checklistTemplateItem);

  let checklistTemplateItemsVersion = state.checklistTemplateItemsVersion + 1;

  updateGroupRanks(groupedChecklistTemplateItems, 0);

  let newState = update(state, {
    checklistTemplate: {
      groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
    },
    checklistTemplateItemsVersion: { $set: checklistTemplateItemsVersion }
  });
  return newState;
}

function addGroupedToParent(items: AusComplyDtos.ChecklistTemplateItem[], newItem: AusComplyDtos.ChecklistTemplateItem, position: number, parentId: number) {
  items.forEach(item => {
    if (item.checklistItemType === AusComplyDtos.ChecklistItemType.Group) {
      if (Math.abs(item.temporaryId) == Math.abs(parentId)) {
        newItem.temporaryParentId = item.temporaryId;
        newItem.parentChecklistTemplateItemId = item.checklistTemplateItemId;
        newItem.checklistTemplateId = item.checklistTemplateId;
        item.groupedItems.splice(position, 0, newItem);
      } else {
        addGroupedToParent(item.groupedItems, newItem, position, parentId);
      }
    }
  });
}

function addGroupedChecklistTemplateItem(state, checklistItemType: AusComplyDtos.ChecklistItemType, position: number, parentId: number) {
  let groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [...state.checklistTemplate.groupedChecklistTemplateItems];


  let newItem: AusComplyDtos.ChecklistTemplateItem = new AusComplyDtos.ChecklistTemplateItem();
  newItem.temporaryParentId = parentId;
  newItem.checklistItemType = checklistItemType;
  newItem.groupedItems = [];
  newItem.temporaryId = commonLogic.uniqueNumber(1);
  newItem.checklistTemplateItemId = -1 * newItem.temporaryId;
  newItem.checklistTemplateId = state.checklistTemplate.checklistTemplateId;
  newItem.checklistTemplateItemOptions = [];
  newItem.checklistTemplateItemFilters = [];
  newItem.checklistTemplateItemNotificationUserRoleTypes = [];
  if (state.checklistTemplate && state.checklistTemplate.checklistTemplate && state.checklistTemplate.checklistTemplate.userRoleTypeSelections) {
    newItem.userRoleTypeSelections = [...state.checklistTemplate.checklistTemplate.userRoleTypeSelections];
  }
  newItem.rank = position;

  switch (checklistItemType) {
    case AusComplyDtos.ChecklistItemType.Group:
      newItem.name = "New Group Text";
      newItem.description = "New Group Description";
      break;
    case AusComplyDtos.ChecklistItemType.Information:
      newItem.name = "New Information Text";
      newItem.description = "New Information Description";
      break;
    case AusComplyDtos.ChecklistItemType.TextBox:
      newItem.name = "New Text Field Text";
      newItem.description = "New Text Field Description";
      break;
    case AusComplyDtos.ChecklistItemType.Question:
      newItem.name = "New Question Text";
      newItem.description = "New Question Description";
      break;
    case AusComplyDtos.ChecklistItemType.Selection:
      newItem.name = "New Selection";
      newItem.description = "New Selection Description";
      break;
    case AusComplyDtos.ChecklistItemType.MultipleChoice:
      newItem.name = "New Multi-Select Text";
      newItem.description = "New Multi-Select Description";
      break;
    case AusComplyDtos.ChecklistItemType.Checkbox:
      newItem.name = "New Checkbox Text";
      newItem.description = "New Checkbox Description";
      break;
    case AusComplyDtos.ChecklistItemType.DateTime:
      newItem.name = "New Date and time Text";
      newItem.description = "New Date and time Description";
      break;
    case AusComplyDtos.ChecklistItemType.StaffSingleSelection:
      newItem.name = "New Single Staff Selection";
      newItem.description = "New Single Staff Selection Description";
      break;
    case AusComplyDtos.ChecklistItemType.StaffMultipleSelection:
      newItem.name = "New Multi Staff Selection";
      newItem.description = "New Multi Staff Selection Description";
      break;
    default:
      break;
  }
  if (parentId === 0) {
    groupedChecklistTemplateItems.splice(position, 0, newItem);
  } else {
    // find the parent and add it
    addGroupedToParent(groupedChecklistTemplateItems, newItem, position, parentId);
  }

  // update the flast list checklistTemplateItems
  let checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [];
  groupedChecklistTemplateItems.forEach(item => {
    checklistTemplateItems.push(item);
    flatten(checklistTemplateItems, item.groupedItems);
  });

  let checklistTemplateItemsVersion = state.checklistTemplateItemsVersion + 1;

  updateGroupRanks(groupedChecklistTemplateItems, 0);

  let newState = update(state, {
    checklistTemplate: {
      checklistTemplateItems: { $set: [...checklistTemplateItems] },
      groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
    },
    checklistTemplateItemsVersion: { $set: checklistTemplateItemsVersion }
  });
  return newState;
}

function removeGroupedChecklistTemplateItem(state, checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {
  let groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [...state.checklistTemplate.groupedChecklistTemplateItems];
  removeItem(groupedChecklistTemplateItems, checklistTemplateItem);

  // update the flast list checklistTemplateItems
  let checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [];
  groupedChecklistTemplateItems.forEach(item => {
    checklistTemplateItems.push(item);
    flatten(checklistTemplateItems, item.groupedItems);
  });

  let checklistTemplateItemsVersion = state.checklistTemplateItemsVersion + 1;

  updateGroupRanks(groupedChecklistTemplateItems, 0);

  let newState = update(state, {
    checklistTemplate: {
      checklistTemplateItems: { $set: [...checklistTemplateItems] },
      groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
    },
    checklistTemplateItemsVersion: { $set: checklistTemplateItemsVersion }
  });
  return newState;
}

function moveGroupedChecklistTemplateItem(state, checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem, position: number, parentId: number) {
  let itemToInsert = { ...checklistTemplateItem };
  let groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [...state.checklistTemplate.groupedChecklistTemplateItems];
  removeItem(groupedChecklistTemplateItems, checklistTemplateItem);

  if (checklistTemplateItem.temporaryParentId === parentId && position > checklistTemplateItem.rank) {
    position = position - 1;
  }
  if (parentId === 0) {
    itemToInsert.temporaryParentId = 0;
    itemToInsert.parentChecklistTemplateItemId = 0;
    groupedChecklistTemplateItems.splice(position, 0, itemToInsert);
  } else {
    // find the parent and add it
    addGroupedToParent(groupedChecklistTemplateItems, itemToInsert, position, parentId);
  }

  // update the flast list checklistTemplateItems
  let checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [];
  groupedChecklistTemplateItems.forEach(item => {
    checklistTemplateItems.push(item);
    flatten(checklistTemplateItems, item.groupedItems);
  });

  let checklistTemplateItemsVersion = state.checklistTemplateItemsVersion + 1;

  updateGroupRanks(groupedChecklistTemplateItems, 0);

  let newState = update(state, {
    checklistTemplate: {
      checklistTemplateItems: { $set: [...checklistTemplateItems] },
      groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
    },
    checklistTemplateItemsVersion: { $set: checklistTemplateItemsVersion }
  });
  return newState;
}

function removeItem(items: AusComplyDtos.ChecklistTemplateItem[], item: AusComplyDtos.ChecklistTemplateItem) {
  for (let index = 0; index < items.length; index++) {
    if (items[index].temporaryId == item.temporaryId) {
      items.splice(index, 1);
      return;
    } else {
      removeItem(items[index].groupedItems, item);
    }
  }
}

function flatten(checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[], groupedItems: AusComplyDtos.ChecklistTemplateItem[]) {
  groupedItems.forEach(item => {
    checklistTemplateItems.push(item);
    flatten(checklistTemplateItems, item.groupedItems);
  });
}

function setChecklistTemplateItem(state, checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {
  let checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [...state.checklistTemplate.checklistTemplateItems];
  let replaceIndex = -1;
  checklistTemplateItems.forEach((item, index) => {
    item.groupedItems = [];
    if (item.temporaryId === checklistTemplateItem.temporaryId) {
      replaceIndex = index;
    }
  });
  // remove any grouping as we are adding this to the flat list version
  checklistTemplateItem.groupedItems = [];
  if (replaceIndex > -1) {
    checklistTemplateItems.splice(replaceIndex, 1, checklistTemplateItem);
  }
  let groupedChecklistTemplateItems = groupChecklistItemList(checklistTemplateItems);

  updateGroupRanks(groupedChecklistTemplateItems, 0);

  let newState = update(state, {
    checklistTemplate: {
      checklistTemplateItems: { $set: [...checklistTemplateItems] },
      groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
    }
  });
  return newState;
}

function savingChecklistTemplate(state, isSaving) {
  let newState = update(state, {
    checklistTemplateSaving: { $set: isSaving }
  });
  return newState;
}

function checklistTemplateSaved(state, successful) {
  let newState = update(state, {
    checklistTemplateSaveSuccessful: { $set: successful }
  });
  return savingChecklistTemplate(newState, false);
}

function updateChecklistTemplates(state, checklistTemplates) {
  let newState = update(state, {
    checklistTemplates: { $set: checklistTemplates }
  });
  return loadingChecklistTemplates(newState, false);
}

function loadingChecklistTemplates(state, isLoading) {
  let newState = update(state, {
    checklistTemplatesLoading: { $set: isLoading }
  });
  return newState;
}

function updateChecklistTemplatesPageSize(state, pageSize) {
  let newState = update(state, {
    checklistTemplatesPageSize: { $set: pageSize }
  });
  return newState;
}

function updateChecklistTemplatesPage(state, page) {
  let newState = update(state, {
    checklistTemplatesPage: { $set: page }
  });
  return newState;
}

function updateChecklistTemplatesPaging(state, paging) {
  if (!paging) {
    return state;
  }
  let newState = update(state, {
    checklistTemplatesPageSize: { $set: paging.pageSize },
    checklistTemplatesPage: { $set: paging.page },
    checklistTemplatesPaging: { $set: paging }
  });
  return newState;
}

function toggleChecklistTemplateSelected(state, checklistTemplateId) {
  let idx = state.selectedChecklistTemplateIds.indexOf(checklistTemplateId);

  if (idx === -1) {
    let newState = update(state, {
      selectedChecklistTemplateIds: { $push: [checklistTemplateId] }
    });
    return newState;
  } else {
    let newState = update(state, {
      selectedChecklistTemplateIds: { $splice: [[idx, 1]] }
    });
    return newState;
  }
}

function clearChecklistTemplateSelection(state) {
  let newState = update(state, {
    selectedChecklistTemplateIds: { $set: [] }
  });
  return newState;
}

function updateChecklistTemplatesSortParameters(state, sortBy, sortDirection) {
  let newState = update(state, {
    checklistTemplatesSortBy: { $set: sortBy },
    checklistTemplatesSortDirection: { $set: sortDirection }
  });
  return newState;
}

function checklistTemplateSearchText(state, searchText) {
  let newState = update(state, {
    checklistTemplateSearch: {
      searchText: { $set: searchText }
    }
  });

  return newState;
}

export default function checklistTemplate(state = initialState, action) {
  switch (action.type) {
    case CHECKLIST_TEMPLATE_RESET:
      return { ...initialState };
    case LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST:
      return loadingChecklistTemplateCategories(state, true);
    case LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE:
      return loadingChecklistTemplateCategories(state, false);
    case LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS:
      return updateChecklistTemplateCategories(
        state,
        action.checklistTemplateCategories
      );

    case LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_REQUEST:
      return loadingChecklistTemplateCategories(state, true);
    case LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_FAILURE:
      return loadingChecklistTemplateCategories(state, false);
    case LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_SUCCESS:
      return updateChecklistTemplateCategories(
        state,
        action.checklistTemplateCategories
      );

    case SAVE_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS:
      return checklistTemplateCategorySaved(
        updateChecklistTemplateCategory(
          state,
          action.checklistTemplateCategory
        ),
        true
      );
    case SAVE_CHECKLIST_TEMPLATE_CATEGORY_REQUEST:
      return savingChecklistTemplateCategory(state, true);
    case SAVE_CHECKLIST_TEMPLATE_CATEGORY_FAILURE:
      return checklistTemplateCategorySaved(state, false);

    case LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS:
      return checklistTemplateCategoryLoaded(
        updateChecklistTemplateCategory(
          state,
          action.checklistTemplateCategory
        ),
        true
      );
    case LOAD_CHECKLIST_TEMPLATE_CATEGORY_FAILURE:
      return checklistTemplateCategoryLoaded(
        loadingChecklistTemplateCategory(state, false),
        false
      );
    case LOAD_CHECKLIST_TEMPLATE_CATEGORY_REQUEST:
      return loadingChecklistTemplateCategory(state, true);
    case TOGGLE_CHECKLIST_TEMPLATE_CATEGORY_SELECTED:
      return toggleChecklistTemplateCategorySelected(
        state,
        action.checklistTemplateCategoryId
      );
    case CLEAR_CHECKLIST_TEMPLATE_CATEGORY_SELECTION:
      return clearChecklistTemplateCategorySelection(state);
    case SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE:
      return updateChecklistTemplateCategoriesPage(state, action.page);
    case SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE_SIZE:
      return updateChecklistTemplateCategoriesPageSize(state, action.pageSize);
    case SET_CHECKLIST_TEMPLATE_CATEGORIES_SORT_PARAMETERS:
      return updateChecklistTemplateCategoriesSortParameters(
        state,
        action.sortBy,
        action.sortDirection
      );

    case SAVE_CHECKLIST_TEMPLATE_SUCCESS:
      return checklistTemplateSaved(
        updateChecklistTemplate(state, action.checklistTemplate),
        true
      );
    case SAVE_CHECKLIST_TEMPLATE_REQUEST:
      return savingChecklistTemplate(state, true);
    case SAVE_CHECKLIST_TEMPLATE_FAILURE:
      return checklistTemplateSaved(state, false);
    case LOAD_CHECKLIST_REQUEST:
      return loadingChecklistTemplate(state, true);
    case LOAD_CHECKLIST_FAILURE:
      return loadingChecklistTemplate(state, false);
    case NEW_CHECKLIST_TEMPLATE_REQUEST:

      return loadingChecklistTemplate(state, true);
    case LOAD_CHECKLIST_TEMPLATE_REQUEST:
      return loadingChecklistTemplate(state, true);
    case LOAD_CHECKLIST_TEMPLATE_SUCCESS:
      return checklistTemplateLoaded(updateChecklistTemplate(state, action.checklistTemplate), true);
    case LOAD_CHECKLIST_TEMPLATE_FAILURE:
      return checklistTemplateLoaded(
        loadingChecklistTemplate(state, false),
        false
      );
    case COPY_CHECKLIST_TEMPLATES_SUCCESS:
      return updateChecklistTemplate(state, action.checklistTemplate);
    case UPDATE_CHECKLIST_TEMPLATE:
      return updateChecklistTemplate(state, action.checklistTemplate);
    case LOAD_CHECKLIST_TEMPLATES_REQUEST:
      return loadingChecklistTemplates(checklistTemplateSearchText(state, action.searchText), true);
    case LOAD_CHECKLIST_TEMPLATES_SUCCESS:
      return updateChecklistTemplates(updateChecklistTemplatesPaging(state, action.paging), action.checklistTemplates);
    case LOAD_CHECKLIST_TEMPLATES_FAILURE:
      return loadingChecklistTemplates(state, false);
    case TOGGLE_CHECKLIST_TEMPLATE_SELECTED:
      return toggleChecklistTemplateSelected(state, action.checklistTemplateId);
    case CLEAR_CHECKLIST_TEMPLATE_SELECTION:
      return clearChecklistTemplateSelection(state);
    case SET_CHECKLIST_TEMPLATES_PAGE:
      return updateChecklistTemplatesPage(state, action.page);
    case SET_CHECKLIST_TEMPLATES_PAGE_SIZE:
      return updateChecklistTemplatesPageSize(state, action.pageSize);
    case SET_CHECKLIST_TEMPLATES_SORT_PARAMETERS:
      return updateChecklistTemplatesSortParameters(
        state,
        action.sortBy,
        action.sortDirection
      );
    case UPDATE_CHECKLIST_TEMPLATE_ITEMS:
      return updateChecklistTemplateItems(state, action.checklistTemplateItems);
    case LOAD_CHECKLIST_PREVIEW_REQUEST:
      return loadingChecklistPreview(state, true);
    case LOAD_CHECKLIST_PREVIEW_SUCCESS:
      return updateChecklistPreview(state, action.checklist);
    case SET_CHECKLIST_TEMPLATE_ITEM:
      return setChecklistTemplateItem(state, action.checklistTemplateItem);
    case SET_CHECKLIST_TEMPLATE_GROUPED_ITEM:
      return setGroupedChecklistTemplateItem(state, action.checklistTemplateItem);
    case ADD_CHECKLIST_TEMPLATE_ITEM:
      return addGroupedChecklistTemplateItem(state, action.checklistItemType, action.position, action.parentId);
    case REMOVE_CHECKLIST_TEMPLATE_ITEM:
      return removeGroupedChecklistTemplateItem(state, action.checklistTemplateItem);
    case MOVE_CHECKLIST_TEMPLATE_ITEM:
      return moveGroupedChecklistTemplateItem(state, action.checklistTemplateItem, action.position, action.parentId);

  }

  return state;
}
