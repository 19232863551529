import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import RadioControlGroup from '../../controls/RadioControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { RadioOption, MulitSelectOption } from '../../../common/dto/common';
import LocalDateControlGroup from '../../controls/LocalDateControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import PageMessage from '../../common/PageMessage';
import UserDisplayView from '../../users/user/UserDisplayView'

export interface IAddUserRoleProps {
    currentVenueId: number;
    currentSecurityFirmId: number;
    userId: number;
    notificationId: number;
    isLoading: boolean;
    forSecurityFirm: boolean;
    forVenueId: number;
    forSecurityFirmId: number;
    userRole: AusComplyDtos.UserRole;
    venues: AusComplyDtos.VenueSelection[];
    securityFirms: AusComplyDtos.SecurityFirmSelection[];
    userRoleTypes: AusComplyDtos.UserRoleType[];
    isSaving: boolean;
    error: string;
    onSuccess: Function;
    onNew: Function;
    onChange: Function;
    onSave: Function;
    onDeny: Function;
    theme: any;
    classes: any;
}

export default class AddUserRole extends Component<IAddUserRoleProps, any> {
    constructor(props: IAddUserRoleProps) {
        super(props)
        this.state = {
            addAnotherRole: false,
            details: false,
            error: {}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.validate = this.validate.bind(this);
        this.onDeny = this.onDeny.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onToggleDetails = this.onToggleDetails.bind(this);
    }

    componentDidMount() {
        // console.log('componentDidMount', this.props.forVenueId, this.props.forSecurityFirmId, this.props.forSecurityFirm, this.props.currentVenueId, this.props.currentSecurityFirmId);
        if ((this.props.forVenueId !== undefined && this.props.forVenueId != 0) || (this.props.forSecurityFirmId != undefined && this.props.forSecurityFirmId != 0)) {
            this.props.onNew(this.props.userId, this.props.forVenueId, this.props.forSecurityFirmId, this.props.forSecurityFirm);
        }
        else {
            this.props.onNew(this.props.userId, this.props.currentVenueId, this.props.currentSecurityFirmId, this.props.forSecurityFirm);
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            let userRole = { ...this.props.userRole };
            userRole.userId = this.props.userId;
            this.props.onChange(userRole);
            this.setState({ error: {} });
        }
        if (!this.props.isSaving && prevProps.isSaving && !this.props.error && this.props.onSuccess) {
            this.setState({ addAnotherRole: false, error: {} });
            if (this.state.addAnotherRole) {
                this.props.onNew(this.props.currentVenueId, this.props.currentSecurityFirmId, this.props.forSecurityFirm);
            } else {
                this.props.onSuccess();
            }
        }
    }

    onToggleDetails() {
        this.setState({
            details: !this.state.details
        });
    }

    onSubmit() {
        if (this.validate()) {
            this.props.onSave(this.props.userRole, 0);
        }
    }

    onConfirm() {
        if (this.validate()) {
            this.props.onSave(this.props.userRole, this.props.notificationId);
        }
    }

    onDeny() {
        this.props.onDeny(this.props.notificationId);
    }

    onValueChanged(fieldName: string, value: any) {
        let userRole = { ...this.props.userRole };
        userRole[fieldName] = value;
        if (fieldName == 'userRoleTypeId') {
            if (this.props.userRoleTypes) {
                this.props.userRoleTypes.forEach(userRoleType => {
                    if (value && value == userRoleType.userRoleTypeId) {
                        userRole.temporary = userRoleType.allowTemporary && !userRoleType.allowPermanent;
                    }
                })
            }
        }
        this.props.onChange(userRole);

        if (fieldName == "securityFirmID") {
            this.props.onNew(this.props.userId, null, value, this.props.forSecurityFirm);
            //this.props.onNew(this.props.userId, null, value, this.props.forSecurityFirm);
        } else if (fieldName == "venueID") {
            this.props.onNew(this.props.userId, value, this.props.userRole.securityFirmID, this.props.forSecurityFirm);
        }
    }

    onAddAnotherRoleChanged(value) {
        this.setState({ addAnotherRole: value });
    }

    selectedUserRoleType() {
        let selectedUserRoleType: AusComplyDtos.UserRoleType = new AusComplyDtos.UserRoleType();
        selectedUserRoleType.userRoleTypeId = 0;
        if (this.props.userRoleTypes) {
            this.props.userRoleTypes.forEach(userRoleType => {
                if (this.props.userRole.userRoleTypeId && this.props.userRole.userRoleTypeId == userRoleType.userRoleTypeId) {
                    selectedUserRoleType = userRoleType;
                }
            })
        }
        return selectedUserRoleType;
    }

    validate() {
        let error = {};
        let isValid = true;
        let userRoleType = this.selectedUserRoleType();
        if (this.props.forSecurityFirm) {
            if (this.props.userRole.securityFirmID == 0) {
                error["securityFirmID"] = "Required";
                isValid = false;
            }
        } else {
            if (this.props.userRole.venueID == 0) {
                error["venueID"] = "Required";
                isValid = false;
            }
        }
        if (!userRoleType || userRoleType.userRoleTypeId == 0) {
            error["userRoleTypeId"] = "Required";
            isValid = false;
        }
        if (userRoleType.allowTemporary && this.props.userRole.temporary && this.props.userRole.expiry.isEmpty) {
            error["expiry"] = "Required";
            isValid = false;
        }
        this.setState({ error: error });
        return isValid;
    }


    render() {
        let isNotification = false;
        if (this.props.notificationId && this.props.notificationId > 0) {
            isNotification = true
        }
        let selectedUserRoleType = this.selectedUserRoleType();
        let options;
        if (this.props.userRoleTypes && this.props.userRoleTypes.length > 0) {
            options = (
                <SelectControlGroup
                    text={"Access Level"}
                    error={this.state.error["userRoleTypeId"]}
                    onChange={(value) => this.onValueChanged("userRoleTypeId", parseInt(value))}
                    defaultValue={this.props.userRole.userRoleTypeId}>
                    {this.props.userRoleTypes && this.props.userRoleTypes.map((item, index) => (
                        <option key={item.userRoleTypeId} value={item.userRoleTypeId}>{"Level " + item.accessLevel.toString() + " (" + item.name + ")"}</option>
                    ))}
                </SelectControlGroup>
            );
        } else {
            options = (
                <ControlGroup text="Access Level">
                    <PageMessage text={"You do not have permission to add any role types for this selection"} />
                </ControlGroup>
            );
        }

        return (
            <LayoutForm
                fullWidth={true}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
            >
                <form>

                    {isNotification && (
                        <>
                            {!this.state.details && this.props.userRole.user && (
                                <>
                                    <ControlGroup text="Name">
                                        <TextDisplay>{this.props.userRole.user.displayName}</TextDisplay>
                                    </ControlGroup>
                                    <ControlGroup text="Email">
                                        <TextDisplay>{this.props.userRole.user.email}</TextDisplay>
                                    </ControlGroup>
                                    <ControlGroup text="Mobile">
                                        <TextDisplay>{this.props.userRole.user.mobileNo}</TextDisplay>
                                    </ControlGroup>
                                    <ControlGroup text=" ">
                                        <TransparentButton onClick={() => this.onToggleDetails()}>Show more details</TransparentButton>
                                    </ControlGroup>
                                </>
                            )}
                            {this.state.details && this.props.userRole.user && (
                                <>
                                    <UserDisplayView user={this.props.userRole.user} />
                                    <ControlGroup text=" ">
                                        <TransparentButton onClick={() => this.onToggleDetails()}>Show less details</TransparentButton>
                                    </ControlGroup>
                                </>
                            )}
                        </>
                    )}
                    {this.props.forSecurityFirm && (
                        <SelectControlGroup
                            text="Security Firm"
                            error={this.state.error["securityFirmID"]}
                            onChange={(value) => this.onValueChanged("securityFirmID", parseInt(value))}
                            defaultValue={this.props.userRole.securityFirmID}>
                            <option value={0} />
                            {this.props.securityFirms && this.props.securityFirms.map((item, index) => (
                                <option key={item.securityFirmId} value={item.securityFirmId}>{item.name}</option>
                            ))}
                        </SelectControlGroup>
                    )}
                    <SelectControlGroup
                        text="Venue"
                        error={this.state.error["venueID"]}
                        onChange={(value) => this.onValueChanged("venueID", parseInt(value))}
                        defaultValue={this.props.userRole.venueID}>
                        <option value={0} />
                        {this.props.venues && this.props.venues.map((item, index) => (
                            <option key={item.venueId} value={item.venueId}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                    {options}
                    {selectedUserRoleType.allowTemporary && (
                        <>
                            <ControlGroup text=" ">
                                <CheckboxControlGroup text={"Temporary"}
                                    defaultValue={this.props.userRole.temporary}
                                    readonly={!selectedUserRoleType.allowPermanent}
                                    onChanged={(value) => this.onValueChanged("temporary", value)} />
                            </ControlGroup>

                            {this.props.userRole.temporary && (
                                <LocalDateControlGroup
                                    text="Expiry Date"
                                    error={this.state.error["expiry"]}
                                    date={this.props.userRole.expiry}
                                    onChange={(value) => this.onValueChanged("expiry", value)} />
                            )}
                        </>
                    )}
                    {!isNotification && (
                        <CheckboxControlGroup text={"Add another role"} defaultValue={this.state.addAnotherRole} onChanged={(value) => this.onAddAnotherRoleChanged(value)} />
                    )}
                    <Grid container spacing={2}>
                        {isNotification && (
                            <>
                                <Grid item xs={6}>
                                    <PrimaryButton text={"CONFIRM"} onClick={this.onConfirm}></PrimaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton text={"DENY"} onClick={this.onDeny}></DefaultButton>
                                </Grid>
                            </>
                        )}
                        {!isNotification && (
                            <Grid item xs={12}>
                                <PrimaryButton text={"ADD"} onClick={this.onSubmit}></PrimaryButton>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </LayoutForm>
        );
    }
}