import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import ResponsiveList from '../../common/ResponsiveList';
import ConfirmDialog from '../../controls/ConfirmDialog';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import PlayTraxEventContainer from '../../../containers/PlayTraxEventContainer';
import { KeyName } from '../../../common/dto/common';
import SimpleFilter from '../../common/SimpleFilter';
import SimpleFilterSummary from '../../common/SimpleFilterSummary';
import View from '../../common/View';
import PlayTraxTabs from './PlayTraxTabs';

export interface IPlayTraxEventsProps {
    isLoading: boolean;
    venueId: number;
    externalEvents: AusComplyDtos.ExternalEvent[];
    externalEventId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    canEdit: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onUpdate: Function;
    onSave: Function;
    history: any;
}

export default class PlayTraxEvents extends Component<IPlayTraxEventsProps, any> {
    constructor(props: IPlayTraxEventsProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            contactToRemove: {},
            filterExpanded: false
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {

    }

    prepareFilter(filter: AusComplyDtos.SimpleFilter) {
        let preparedFilter = { ...filter };
        if (this.props.venueId && this.props.venueId > 0) {
            preparedFilter.forVenueId = this.props.venueId;
        } 
        preparedFilter.entityType = AusComplyDtos.ngtEntityType.PlayTrax;
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    getCommands(externalEvent: AusComplyDtos.ExternalEvent) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        return commands;
    }

    onCommand(command: string, externalEvent: AusComplyDtos.ExternalEvent) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId);
                break;
            case "edit":
                this.props.onLoadItem(externalEvent.externalEventId);
                break;
            case "delete":
                var updated = { ...externalEvent };
                updated.obsolete = true;
                this.setState({
                    confirmRemove: true,
                    contactToRemove: updated
                });
                break;
            default:
                break;
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            contactToRemove: {}
        });
    }

    removeConfirmed() {
        if (this.props.onSave) {
            let externalEvent = { ...this.state.contactToRemove };
            this.props.onUpdate(externalEvent);
            this.props.onSave();
            this.setState({
                confirmRemove: false,
                contactToRemove: {}
            });
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onLoad(this.props.paging.pageSize, 1, this.props.filter);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onSearch(filter: AusComplyDtos.SimpleFilter) {  
        this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter({...filter}));     
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter(new AusComplyDtos.SimpleFilter()));
        this.setState({ filterExpanded: false });
    }

    render() {
        let self = this;

        let create;
        if (this.props.canEdit) {
            create = (
                <TransparentButton
                    onClick={() => this.props.onCreate(this.props.venueId)}
                    text={"Add"} />
            );
        }

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <SimpleFilter
                filter={this.props.filter}
                onReset={() => self.onResetSearch()}
                onSearch={(filter) => self.onSearch(filter)}
            />;
        } else if (this.props.isLoading) {
            let simpleFilter = new AusComplyDtos.SimpleFilter();
            simpleFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={simpleFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (<PageLayout
            headerText={"PlayTrax"}
            loading={this.props.isLoading}
            footerLeftContent={create}
            tabContent={<PlayTraxTabs selectedTab='Events' history={this.props.history} />}
        >
            {filterSummary}
            <ResponsiveList
                loading={this.props.isLoading}
                data={this.props.externalEvents}
                paging={this.props.paging}
                onPageSelected={(page) => this.props.onLoad(this.props.paging.pageSize, page, this.props.filter)}
                onPageSizeSelected={(page, pageSize) => this.props.onLoad(pageSize, page, this.props.filter)}
                headers={["Name"]}
                columns={["display"]}
                getCommands={item => this.getCommands(item)}
                onCommand={(command, item) => this.onCommand(command, item)}
                isObsolete={item => item.obsolete}
                canEdit={true}
            />
            <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                {filter}
            </FullPageDialog>
            <PlayTraxEventContainer />
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this event?"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
        </PageLayout>);
    }
}