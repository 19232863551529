import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import * as AusComplyDtos from "../common/dto/AusComply.dtos";
import PlayTraxEvents from '../components/externalEvents/playTrax/PlayTraxEvents'
import * as externalEventActions from '../common/actions/externalEvent';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        paging: state.externalEvents.externalEvents.paging,
        filter: state.externalEvents.externalEvents.filter,
        isLoading: state.externalEvents.externalEvents.isLoading,
        externalEvents: state.externalEvents.externalEvents.externalEvents,
        canEdit: permissionsLogic.hasPermissionForState(state, "CashPlayTraxManager")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(externalEventActions.externalEventsRequest(page, pageSize, filter))
        },
        onLoadItem: (externalEventId) => {
            dispatch(externalEventActions.externalEventRequest(externalEventId))
        },
        onCreate: (forVenueId) => {
            dispatch(externalEventActions.externalEventCreateRequest(AusComplyDtos.ngtEntityType.PlayTrax, undefined, forVenueId, undefined))
        },
        onUpdate: (externalEvent) => {
            dispatch(externalEventActions.externalEventUpdate(externalEvent))
        },
        onSave: () => {
            dispatch(externalEventActions.externalEventSaveRequest())
        }
    }
}

const VenuePlayTraxEventContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayTraxEvents))

export default VenuePlayTraxEventContainer