import { makeActionCreator } from './helpers';

export const
    EXTERNALEVENTS_REQUEST = "EXTERNALEVENTS_REQUEST",
    EXTERNALEVENTS_REQUEST_SUCCESS = "EXTERNALEVENTS_REQUEST_SUCCESS",
    EXTERNALEVENTS_REQUEST_FAILURE = "EXTERNALEVENTS_REQUEST_FAILURE",
    EXTERNALEVENTS_REFRESH = "EXTERNALEVENTS_REFRESH",
    EXTERNALEVENT_CREATE_REQUEST = "EXTERNALEVENT_CREATE_REQUEST",
    EXTERNALEVENT_CREATE_REQUEST_SUCCESS = "EXTERNALEVENT_CREATE_REQUEST_SUCCESS",
    EXTERNALEVENT_CREATE_REQUEST_FAILURE = "EXTERNALEVENT_CREATE_REQUEST_FAILURE",
    EXTERNALEVENT_REQUEST = "EXTERNALEVENT_REQUEST",
    EXTERNALEVENT_REQUEST_SUCCESS = "EXTERNALEVENT_REQUEST_SUCCESS",
    EXTERNALEVENT_REQUEST_FAILURE = "EXTERNALEVENT_REQUEST_FAILURE",
    EXTERNALEVENT_UPDATE = "EXTERNALEVENT_UPDATE",
    EXTERNALEVENT_SAVE_REQUEST = "EXTERNALEVENT_SAVE_REQUEST",
    EXTERNALEVENT_SAVE_REQUEST_SUCCESS = "EXTERNALEVENT_SAVE_REQUEST_SUCCESS",
    EXTERNALEVENT_SAVE_REQUEST_FAILURE = "EXTERNALEVENT_SAVE_REQUEST_FAILURE",
    EXTERNALEVENT_RESET = "EXTERNALEVENT_RESET",

    EXTERNALEVENTMESSAGEAUDITS_REQUEST = "EXTERNALEVENTMESSAGEAUDITS_REQUEST",
    EXTERNALEVENTMESSAGEAUDITS_REQUEST_SUCCESS = "EXTERNALEVENTMESSAGEAUDITS_REQUEST_SUCCESS",
    EXTERNALEVENTMESSAGEAUDITS_REQUEST_FAILURE = "EXTERNALEVENTMESSAGEAUDITS_REQUEST_FAILURE",
    EXTERNALEVENTMESSAGEAUDITS_REFRESH = "EXTERNALEVENTMESSAGEAUDITS_REFRESH",
    EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST = "EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST",
    EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST_SUCCESS = "EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST_SUCCESS",
    EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST_FAILURE = "EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST_FAILURE"
    ;

export const externalEventsRequest = makeActionCreator(EXTERNALEVENTS_REQUEST, 'page', 'pageSize', 'filter');
export const externalEventsRequestSuccess = makeActionCreator(EXTERNALEVENTS_REQUEST_SUCCESS, 'data', 'paging', 'filter');
export const externalEventsRequestFailure = makeActionCreator(EXTERNALEVENTS_REQUEST_FAILURE, 'error');
export const externalEventCreateRequest = makeActionCreator(EXTERNALEVENT_CREATE_REQUEST, 'ngtEntityType', 'forGroupId', 'forVenueId', 'forSecurityFirmId');
export const externalEventCreateRequestSuccess = makeActionCreator(EXTERNALEVENT_CREATE_REQUEST_SUCCESS, 'externalEvent');
export const externalEventCreateRequestFailure = makeActionCreator(EXTERNALEVENT_CREATE_REQUEST_FAILURE, 'error');
export const externalEventRequest = makeActionCreator(EXTERNALEVENT_REQUEST, 'externalEventId');
export const externalEventRequestSuccess = makeActionCreator(EXTERNALEVENT_REQUEST_SUCCESS, 'externalEvent');
export const externalEventRequestFailure = makeActionCreator(EXTERNALEVENT_REQUEST_FAILURE, 'error');
export const externalEventSaveRequest = makeActionCreator(EXTERNALEVENT_SAVE_REQUEST, 'externalEvent');
export const externalEventSaveRequestSuccess = makeActionCreator(EXTERNALEVENT_SAVE_REQUEST_SUCCESS, 'externalEvent');
export const externalEventSaveRequestFailure = makeActionCreator(EXTERNALEVENT_SAVE_REQUEST_FAILURE, 'error');
export const externalEventUpdate = makeActionCreator(EXTERNALEVENT_UPDATE, 'externalEvent');
export const externalEventsRefresh = makeActionCreator(EXTERNALEVENTS_REFRESH);
export const externalEventReset = makeActionCreator(EXTERNALEVENT_RESET);

export const externalEventMessageAuditsRequest = makeActionCreator(EXTERNALEVENTMESSAGEAUDITS_REQUEST, 'page', 'pageSize', 'filter');
export const externalEventMessageAuditsRequestSuccess = makeActionCreator(EXTERNALEVENTMESSAGEAUDITS_REQUEST_SUCCESS, 'data', 'paging', 'filter');
export const externalEventMessageAuditsRequestFailure = makeActionCreator(EXTERNALEVENTMESSAGEAUDITS_REQUEST_FAILURE, 'error');
export const externalEventMessageAuditsRefresh = makeActionCreator(EXTERNALEVENTMESSAGEAUDITS_REFRESH);
export const externalEventMessageAuditReProcessRequest = makeActionCreator(EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST, 'externalEventMessageAudit');
export const externalEventMessageAuditReProcessRequestSuccess = makeActionCreator(EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST_SUCCESS);
export const externalEventMessageAuditReProcessRequestFailure = makeActionCreator(EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST_FAILURE, 'error');