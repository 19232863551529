import update from "immutability-helper";
import {
    PRINT_RESET,
    PRINT_OPTIONS_REQUEST,
    PRINT_OPTIONS_REQUEST_SUCCESS,
    PRINT_OPTIONS_REQUEST_FAILED,
    PRINT_SHOW,
    PRINT_HIDE,
    PRINT_UPDATE_OPTIONS,
    PRINT_INCIDENTS_EMAIL_REQUEST,
    PRINT_INCIDENTS_EMAIL_REQUEST_SUCCESS,
    PRINT_INCIDENTS_EMAIL_REQUEST_FAILED,
    PRINT_INCIDENTS_PDF_REQUEST,
    PRINT_INCIDENTS_PDF_REQUEST_SUCCESS,
    PRINT_INCIDENTS_PDF_REQUEST_FAILED,
    PRINT_INCIDENTS_CSV_REQUEST,
    PRINT_INCIDENTS_CSV_REQUEST_SUCCESS,
    PRINT_INCIDENTS_CSV_REQUEST_FAILED,
    PRINT_QUEUE_DOWNLOADED_REQUEST,
    PRINT_QUEUE_DOWNLOADED_REQUEST_SUCCESS,
    PRINT_QUEUE_DOWNLOADED_REQUEST_FAILED,
    PRINT_QUEUE_REQUEST,
    PRINT_QUEUE_REQUEST_SUCCESS,
    PRINT_QUEUE_REQUEST_FAILED,
    PRINT_QUEUE_DISMISS_REQUEST,
    PRINT_QUEUE_DISMISS_REQUEST_SUCCESS,
    PRINT_QUEUE_DISMISS_REQUEST_FAILED,
    PRINT_QUEUE_DISMISS_ALL_REQUEST,
    PRINT_QUEUE_DISMISS_ALL_REQUEST_SUCCESS,
    PRINT_QUEUE_DISMISS_ALL_REQUEST_FAILED,
    PRINT_INCIDENT_REQUEST,
    PRINT_INCIDENT_REQUEST_SUCCESS,
    PRINT_INCIDENT_REQUEST_FAILED,
    PRINT_CHECKLIST_REQUEST,
    PRINT_CHECKLIST_REQUEST_FAILED,
    PRINT_CHECKLIST_REQUEST_SUCCESS,
    PRINT_SIGNONOFFREGISTERS_REQUEST,
    PRINT_SIGNONOFFREGISTERS_REQUEST_SUCCESS,
    PRINT_SIGNONOFFREGISTERS_REQUEST_FAILED,
    PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST,
    PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_SUCCESS,
    PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_FAILED
} from "../actions/print";

const initialState = {
    isLoading: false,
    printOptions: { recipients: [] },
    queue: [],
    show: false
};

function setLoading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setShowing(state, show) {
    let newState = update(state, {
        show: { $set: show }
    });
    return newState;
}

function setPrintOptions(state, printOptions) {
    let newState = update(state, {
        printOptions: { $set: printOptions }
    });
    return newState;
}

function setQueue(state, queue) {
    let newState = update(state, {
        queue: { $set: queue }
    });
    return newState;
}

function removeFromQueue(state, reportGenerationId) {
    let queue = [...state.queue].filter(item => item.reportGenerationId !== reportGenerationId);
    let newState = update(state, {
        queue: { $set: queue }
    });
    return newState;
}

export default function print(state = initialState, action) {
    switch (action.type) {
        case PRINT_RESET:
            return { ...initialState };
        case PRINT_OPTIONS_REQUEST:
            return setLoading(state, true);
        case PRINT_OPTIONS_REQUEST_SUCCESS:
            return setLoading(setPrintOptions(state, action.printOptions), false);
        case PRINT_OPTIONS_REQUEST_FAILED:
            return setLoading(state, false);
        case PRINT_SHOW:
            return setShowing(state, true);
        case PRINT_HIDE:
            return setShowing(state, false);
        case PRINT_UPDATE_OPTIONS:
            return setPrintOptions(state, action.printOptions);
        case PRINT_INCIDENTS_EMAIL_REQUEST:
            return setLoading(state, true);
        case PRINT_INCIDENTS_EMAIL_REQUEST_SUCCESS:
            return setLoading(setShowing(state, false), false);
        case PRINT_INCIDENTS_EMAIL_REQUEST_FAILED:
            return setLoading(state, false);
        case PRINT_INCIDENTS_PDF_REQUEST:
            return setLoading(state, true);
        case PRINT_INCIDENTS_PDF_REQUEST_SUCCESS:
            return setLoading(setShowing(state, false), false);
        case PRINT_INCIDENTS_PDF_REQUEST_FAILED:
            return setLoading(state, false);
        case PRINT_INCIDENTS_CSV_REQUEST:
            return setLoading(state, true);
        case PRINT_INCIDENTS_CSV_REQUEST_SUCCESS:
            return setLoading(setShowing(state, false), false);
        case PRINT_INCIDENTS_CSV_REQUEST_FAILED:
            return setLoading(state, false);
        case PRINT_QUEUE_REQUEST:
            return state;
        case PRINT_QUEUE_REQUEST_SUCCESS:
            return setQueue(state, action.items);
        case PRINT_QUEUE_REQUEST_FAILED:
            return state;
        case PRINT_QUEUE_DISMISS_REQUEST:
            return removeFromQueue(state, action.reportGenerationId);
        case PRINT_QUEUE_DISMISS_REQUEST_SUCCESS:
            return state;
        case PRINT_QUEUE_DISMISS_REQUEST_FAILED:
            return state;
        case PRINT_QUEUE_DOWNLOADED_REQUEST:
            return removeFromQueue(state, action.reportGenerationId);
        case PRINT_QUEUE_DOWNLOADED_REQUEST_SUCCESS:
            return state;
        case PRINT_QUEUE_DOWNLOADED_REQUEST_FAILED:
            return state;
        case PRINT_QUEUE_DISMISS_ALL_REQUEST:
            return setQueue(state, []);
        case PRINT_QUEUE_DISMISS_ALL_REQUEST_SUCCESS:
            return state;
        case PRINT_QUEUE_DISMISS_ALL_REQUEST_FAILED:
            return state;
        case PRINT_INCIDENT_REQUEST:
            return setLoading(state, true);
        case PRINT_INCIDENT_REQUEST_SUCCESS:
            return setLoading(setShowing(state, false), false);
        case PRINT_INCIDENT_REQUEST_FAILED:
            return setLoading(state, false);
        case PRINT_CHECKLIST_REQUEST:
            return setLoading(state, true);
        case PRINT_CHECKLIST_REQUEST_SUCCESS:
            return setLoading(setShowing(state, false), false);
        case PRINT_CHECKLIST_REQUEST_FAILED:
            return setLoading(state, false);
        case PRINT_SIGNONOFFREGISTERS_REQUEST:
            return setLoading(state, true);
        case PRINT_SIGNONOFFREGISTERS_REQUEST_SUCCESS:
            return setLoading(setShowing(state, false), false);
        case PRINT_SIGNONOFFREGISTERS_REQUEST_FAILED:
            return setLoading(state, false);
        case PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST:
            return setLoading(state, true);
        case PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_SUCCESS:
            return setLoading(setShowing(state, false), false);
        case PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_FAILED:
            return setLoading(state, false);
    }

    return state;
}
